.audio-wrap
	@apply p-1 rounded-full bg-secondary-1 md:rem:max-w-[680px]
.audio-flex
	@apply flex items-center justify-between gap-2 xl:rem:gap-[10px]
.audio-center
	@apply flex-1 flex items-center gap-2
.audio-right
	@apply relative
.audio-toggle-play
	@apply size-8 bg-primary-2 flex-center text-white text-base rounded-full
	&::before
		@apply font-awesome-sharp content-['\f04b'] font-light
	&.playing
		&::before
			@apply content-['\f04c'] font-bold
.audio-timer-duration
	@apply flex items-center text-sm font-normal text-neutral-950 md:w-25
.audio-timeline
	@apply w-full relative cursor-pointer
.audio-empty
	@apply h-[4px] rounded-full bg-neutral-200 w-full
.audio-current
	@apply absolute top-0 left-0 w-0 h-full bg-primary-2 rounded-full
	.audio-node
		@apply absolute top-1/2 -translate-y-1/2 -right-[4px] size-[8px] rounded-full bg-primary-2 cursor-pointer
.audio-voice-current
	@apply flex items-center gap-1 text-base text-neutral-950 whitespace-nowrap w-max cursor-pointer md:rem:w-[140px]
	&::after
		@apply font-awesome-sharp content-['\f078'] text-[10px] font-bold
.audio-voice-list
	@apply hidden absolute top-8 -right-2 p-2 rounded-2 bg-secondary-2 w-max z-1
	li
		@apply text-sm font-normal text-neutral-950 cursor-pointer py-1
	&::after
		content: ""
		width: 0
		height: 0
		border-left: 8px solid rgba(0,0,0,0)
		border-right: 8px solid rgba(0,0,0,0)
		border-bottom: 8px solid theme('colors.secondary.2')
		position: absolute
		top: -7px
		right: 15px
