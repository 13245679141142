.site-nav-list
	@apply flex overflow-x-auto h-full gap-3 xl:gap-5
	&::-webkit-scrollbar
		@apply size-0
	a
		@apply flex items-center h-12 body-16 font-medium text-neutral-950 border-b border-transparent whitespace-nowrap
	li
		@apply w-max flex items-center gap-3 last:after:hidden xl:gap-5
		&::after
			content: ''
			@apply w-px h-3 bg-neutral-200
		&.active, &:hover
			a
				@apply text-primary-2 border-primary-3

.tabslet-tabs
	@apply flex items-center overflow-x-auto gap-3 xl:justify-center xl:overflow-visible
	a
		@apply flex-center gap-3 body-16 font-medium text-neutral-950 border border-neutral-200 bg-neutral-100 rounded-full h-11 px-5 py-2 whitespace-nowrap
		@apply hover:bg-primary-1 hover:text-white hover:border-primary-1
	li
		@apply w-max
		&.active
			a
				@apply bg-primary-1 text-white border-primary-1

.site-sticky
	@apply sticky top-[60px] z-40 bg-white overflow-hidden border-y border-neutral-100 xl:top-25 xl:z-50

.about-1-item
	@apply bg-white
	.year
		@apply sub-header-24 font-bold mb-2 xl:hidden

.about-1-circle-item
	@apply pt-full rounded-full relative text-primary-2 text-center cursor-pointer
	&::before
		content: ''
		@apply absolute pointer-events-none
	&::after
		content: ''
		@apply absolute size-0 pointer-events-none
	*
		@apply tsn
	.caption
		@apply absolute-center size-full flex-center flex-col gap-1 p-2 z-2 rounded-full overflow-hidden xl:p-5
		&::before
			content: ''
			@apply absolute size-full -z-2 border-2 border-current pointer-events-none rounded-full
		&::after
			content: ''
			@apply w-full h-0 absolute left-0 bottom-0 -z-1 bg-current pointer-events-none tsn rounded-full
	.year
		@apply text-[10px] font-bold text-current md:text-2xl
	.title
		@apply text-[10px] font-medium text-black md:text-base
	&:hover, &.active
		.caption
			@apply after:h-full
			*
				@apply text-white #{!important}
	&.is-dark-blue
		@apply text-primary-2
	&.is-light-blue
		@apply text-primary-3
	&.is-red
		@apply text-primary-1
	&:nth-child(1)
		&::before
			@apply top-1/2 -translate-y-1/2 -right-5 w-5 h-[2px] bg-primary-2 md:w-10 md:-right-10
		&::after
			border-top: 5px solid transparent
			border-bottom: 5px solid transparent
			border-left: 10px solid theme('colors.primary.2')
			@apply top-1/2 -translate-y-1/2 -right-5 md:-right-10
	&:nth-child(2)
		&::before
			@apply left-1/2 -translate-x-1/2 -bottom-5 h-5 w-[2px] bg-primary-3 md:h-10 md:-bottom-10
		&::after
			border-left: 5px solid transparent
			border-right: 5px solid transparent
			border-top: 10px solid theme('colors.primary.3')
			@apply left-1/2 -translate-x-1/2 -bottom-5 md:-bottom-10
	&:nth-child(3), &:nth-child(4)
		&::before
			@apply top-1/2 -translate-y-1/2 -right-5 w-5 h-[2px] bg-primary-3 md:w-10 md:-right-10
		&::after
			border-top: 5px solid transparent
			border-bottom: 5px solid transparent
			border-left: 10px solid theme('colors.primary.3')
			@apply top-1/2 -translate-y-1/2 -right-5 md:-right-10
	&:nth-child(4)
		.year
			@apply text-primary-1
	&:nth-child(n+3)
		@apply row-start-2

.about-1-circle-list
	@apply grid grid-cols-3 gap-5 md:gap-10

.about-1-section
	.block-content
		@apply xl:rem:max-w-[640px] xl:py-10
	.about-1-slider
		@apply xl:pb-0
	.swiper-slide
		.about-1-item
			@apply opacity-0
		&.swiper-slide-active
			.about-1-item
				@apply opacity-100

.about-2-section
	.image
		@apply aspect-[1920/640]
	.block-content
		@apply border-t border-secondary-2
	@screen xl
		@apply sticky top-0 left-0 -z-1

.about-3-list
	@apply grid grid-cols-1 gap-10 md:grid-cols-2

.about-3-item
	@apply aspect-[320/416] relative
	.caption
		@apply absolute left-1/2 -translate-x-1/2 w-[calc(100%-(40/1920*100rem))] z-1 rem:top-[100px] flex flex-col gap-3 rem:py-[10px] border-t
	.title
		@apply text-32 font-light leading-tight -tracking-[1.28px]
	&.is-white
		.caption
			@apply border-white/40
		.title,.btn-lined
			@apply text-white border-current
	&.is-primary-2
		.caption
			@apply border-primary-2/60
		.title,.btn-lined
			@apply text-primary-2 border-current

.about-3-section
	@apply  xl:pt-23 xl:rem:pb-[132px]
	.block-content
		@apply rem:max-w-[520px]
	.swiper-slide
		@apply rem:w-[320px]
	@screen xl
		@apply sticky top-0 left-0 -z-1

.about-4-item
	@apply flex flex-col
	.image
		@apply rem:h-[262px]
	.caption
		background: linear-gradient(90deg, rgba(0, 174, 239, 0.05) 0%, #0090E1 50%, rgba(0, 174, 239, 0.05) 100%)
		@apply flex flex-col items-center justify-center gap-1 px-6 py-2 text-center rem:min-h-[80px] flex-1
	.name
		@apply body-18 font-bold text-white
	.sub-name
		@apply body-16 font-normal text-white

.about-4-list
	@apply grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3
	& + .about-4-list
		@apply rem:mt-[22px]
	&.is-bot
		@apply gap-6 lg:grid-cols-4
		.about-4-item
			.caption
				@apply rem:min-h-[72px] py-2

.about-4-section
	.tabslet-content
		@apply rem:mt-[34px] xl:rem:min-h-[738px]
		@apply xl:hidden #{!important}

.about-5-item
	@apply flex gap-4 bg-white rounded-2 p-4 body-18 font-normal text-primary-2 tsn xl:px-6
	@apply hover:bg-primary-2/10
	.number
		@apply font-bold

.about-5-list
	@apply space-y-1

.about-5-section
	.block-content
		@apply pb-10 border-t border-neutral-200
	.main-tabslet
		@apply bg-neutral-50 p-5 rounded-2 xl:rounded-4 xl:p-10
	.about-5-list
		@apply mt-5 xl:mt-10

.about-6-table
	@apply bg-white rounded-2 p-4 xl:pt-6 xl:px-10 xl:pb-10
	table
		@apply w-full
	th
		@apply sub-header-24 font-bold text-primary-2 text-left py-3 pr-3 border-0 border-b border-primary-3 first:text-center xl:pr-5 xl:py-4
	td
		@apply body-18 font-normal text-primary-2 py-3 pr-3 border-0 border-b border-neutral-200 first:text-center xl:pr-5
		&:nth-child(1)
			@apply text-neutral-500
		&:nth-child(2)
			@apply font-bold
	@media only screen and (max-width: 1023.98px)
		@apply overflow-x-auto pb-5
		table
			@apply min-w-[1000px]

.about-6-section
	.about-6-table
		@apply mt-10

.site-quick-link-section
	@apply relative z-1 rem:py-[105px]
	&::before
		content: ''
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%)
		@apply absolute inset-0 -z-1
