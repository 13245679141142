.news-date
	@apply text-[14px] font-normal text-neutral-500 xl:text-sm

.news-category
	@apply text-[14px] font-medium text-primary-4 uppercase pr-2 xl:text-sm

.news-flex
	@apply flex items-center flex-wrap
	> *
		@apply flex items-center last:after:hidden
		&::after
			content: ''
			@apply block w-[2px] h-[10px] bg-neutral-200 ml-[16px] mr-[8px] xl:rem:h-[10px] xl:rem:w-[2px] xl:ml-4 xl:mr-2

.news-item
	@apply flex flex-col gap-4
	.image
		@apply img-cover rounded-[8px] xl:rounded-4
	.caption
		@apply py-2 flex-1
	.news-flex
		@apply mb-3
	.title
		@apply body-18 font-normal text-black tsn
		a
			@apply line-clamp-3
	.desc
		@apply mt-3 body-16 font-normal text-neutral-500 line-clamp-3
	.button
		@apply mt-5
	&:hover
		.title
			@apply text-primary-2
	&.is-hor
		@apply flex gap-[20px] flex-row
		.image
			@apply w-[150px] h-[100px] aspect-[150/100] rounded-[8px]
		.caption
			@apply flex-1 py-[4px] xl:py-2
		.news-flex
			@apply mb-[8px] xl:mb-3
		.title
			@apply body-18 font-normal leading-snug
			a
				@apply line-clamp-4
		@screen xl
			@apply gap-5
			.image
				@apply rem:w-[160px] h-25 aspect-[160/100] rounded-4
	&.is-big
		@apply gap-[20px] xl:gap-5
		.image
			@apply aspect-[358/228] rounded-[8px]
		.caption
			@apply p-0
		.news-flex
			@apply mb-[12px] xl:mb-5
		.title
			@apply sub-header-24 font-semibold
		@screen xl
			.image
				@apply aspect-[600/380] rounded-4

	&.is-big-hor
		@apply rounded-4 overflow-hidden md:flex-row
		> *
			@apply md:w-1/2
		.image
			@apply md:aspect-[700/400] md:rounded-none
		.caption
			@apply flex flex-col justify-center p-5 md:bg-white md:backdrop-blur md:px-8 xl:p-16
		.news-flex
			@apply mb-5
		.title
			@apply sub-header-24 font-bold
			a
				@apply line-clamp-2
		.desc
			@apply mt-5
		.button
			@apply xl:mt-10

.news-1-section
	.news-1-top
		& +  .news-1-bot
			@apply pt-10 mt-7 border-t border-neutral-200 xl:pt-15 xl:pb-5
	.news-list-2-wrap
		@apply mt-10
	.swiper-relative
		& + .news-list-2-wrap
			@apply pt-10 mt-7 border-t border-neutral-200
	.form-group
		@apply flex justify-end items-center gap-3
		label
			@apply body-16 font-normal text-primary-2
	.swiper-slide
		@apply rem:w-[320px]
	@screen xl
		@apply overflow-hidden
		.news-1-bot .swiper
			@apply overflow-visible

.gasoline-price-item
	@apply relative
	.title
		@apply body-18 font-medium text-white whitespace-nowrap xl:font-bold
	.number
		@apply flex items-center gap-2 text-[40px] font-bold text-white leading-1.2 mt-[4px] xl:mt-4 xl:rem:text-[40px]
	.icon
		@apply text-base
		&.sort-up
			@apply text-[#2AAF53]
		&.sort-down
			@apply text-[#D20000]

.gasoline-prices-list
	@apply flex overflow-x-auto overflow-y-hidden xl:grid-cols-4 xl:gap-10
	&::-webkit-scrollbar
		@apply size-0 hidden
	.gasoline-price-item
		@apply w-max px-[12px] border-l border-white/40

.box-gasoline-prices
	@apply bg-primary-2 px-[20px] py-[32px] rounded-[8px] flex flex-col gap-[32px] xl:px-8 xl:py-11 xl:flex-row xl:justify-between xl:rounded-4 xl:gap-5
	.box-title
		@apply sub-header-24 font-semibold text-white flex items-center gap-3 py-[5px] xl:gap-5 xl:py-0
	.sub-box-title
		@apply mt-[8px] body-18 font-medium text-white xl:mt-4 xl:font-bold

.announcement-price-item
	@apply flex flex-col gap-4 rounded-4 border-2 border-neutral-200 bg-white p-4 xl:p-6
	.title
		@apply sub-header-24 font-bold text-primary-2
		a
			@apply line-clamp-2
	.desc
		@apply body-16 font-normal text-black line-clamp-4 h-22
	&:hover
		.title
			@apply text-primary-1
	&.no-border
		@apply border-0

.announcement-price-list
	@apply grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4


.media-item
	@apply flex flex-col rounded-4 overflow-hidden
	.image
		@apply aspect-[296/196] xl:aspect-[640/344]
	.caption
		@apply p-[20px] bg-white flex-1 xl:p-6
	.title
		@apply py-2 border-b border-black/20 text-[18px] font-bold text-primary-2 leading-[1.33] tsn xl:text-2xl
		a
			@apply flex items-center gap-2 xl:gap-4
		.icon
			@apply flex-center text-lg size-10 rounded-full bg-primary-2 text-white xl:size-12 xl:text-2xl
			~ span
				@apply flex-1 line-clamp-2
	.desc
		@apply text-[16px] font-normal text-black mt-5 xl:text-base
	&:hover
		.title
			@apply text-primary-1
	@screen md
		&.is-big-hor
			@apply flex-row
			> *
				@apply w-1/2
			.caption
				@apply flex flex-col justify-center
	@screen xl
		&.is-big-hor
			.image
				@apply aspect-[700/440]
			.caption
				@apply p-20

.news-3-section
	.swiper-slide
		@apply rem:w-[320px]

.news-4-section
	.tabslet-tabs
		@apply justify-start
	.swiper-slide
		@apply rem:w-[320px]
	.media-list
		@apply grid grid-cols-1 gap-10 lg:grid-cols-2
		.media-item
			&:nth-child(n+2)
				@screen md
					@apply flex-row
					.image
						@apply rem:w-[360px] aspect-[360/240]
					.caption
						@apply py-5
					.title
						@apply sub-header-20
		@screen lg
			.media-item
				&:nth-child(1)
					@apply row-span-2

.flipbook-wrapper
	@apply px-[16px] py-[32px] rounded-[8px] xl:px-10 xl:py-10 xl:rounded-4 bg-secondary-1
	.site-title-large
		@apply bg-gradient-4 bg-primary-3 p-[8px] rounded-[8px] xl:p-4 xl:rounded-2 xl:py-2

.flipbook
	@apply overflow-hidden rem:max-w-[1124px] mx-auto

#flipbok_example
	@apply bg-secondary-1 rem:max-w-[858px] mx-auto overflow-visible #{!important}
	> .df-ui-btn
		@apply flex-center p-0 size-10 rounded-full border border-primary-2 text-primary-2 text-lg opacity-100 xl:size-12 xl:text-xl #{!important}
		&::before
			@apply font-awesome font-normal text-current #{!important}
		&.df-ui-next
			@apply xl:rem:-right-[128px] #{!important}
			&::before
				content: '\f054' !important
		&.df-ui-prev
			@apply xl:rem:-left-[128px] #{!important}
			&::before
				content: '\f053' !important
	> .df-ui-wrapper.df-ui-controls
		display: none !important
	> .df-outline-container.df-sidemenu
		display: none !important
	@media only screen and (max-width: 575.98px)
		> .df-ui-btn
			&.df-ui-next
				@apply translate-y-0 -translate-x-1/2 top-auto bottom-0 left-[calc(50%+25px)] size-[40px]  #{!important}
			&.df-ui-prev
				@apply translate-y-0 -translate-x-1/2 top-auto bottom-0 left-[calc(50%-25px)] size-[40px] #{!important}

.news-list-2-wrap
	.news-packery-item
		@apply w-full sm:w-[calc((100%/2)-40px)] lg:w-[calc((100%/3)-40px)]
	.news-packery-list
		@apply sm:-mr-[40px]

.media-section
	.form-group
		@apply flex items-center justify-end gap-3
		label
			@apply body-16 font-normal text-primary-2
	.media-list
		@apply grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3
		.media-item
			.image
				@apply xl:aspect-[440/280]
			.caption
				@apply xl:py-5
			.title
				@apply xl:text-xl
				a
					@apply xl:gap-3
				.icon
					@apply size-10 text-base
