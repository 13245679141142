[data-fancybox]
	@apply cursor-pointer

.image-video
	@apply relative
	&::before
		@apply font-awesome-sharp content-['\f04b'] absolute-center size-16 flex-center bg-primary-2 rounded-full text-2xl font-thin text-white pointer-events-none tsn z-1
	&:hover
		@apply before:scale-110

.box-easy-1
	@apply spacing-mobile bg-gradient-4 flex flex-col gap-5 xl:p-10
	.image
		@apply aspect-[660/330]

.easy-1-section
	background-position: bottom right !important
	@apply xl:rem:py-[90px]
	.block-content
		@apply rem:max-w-[620px] xl:ml-auto xl:mr-0
	.site-desc
		h2
			@apply sub-header-24 font-semibold text-primary-2 mb-5
			a
				@apply underline

.easy-2-item
	@apply flex flex-col gap-4 text-center border-4 border-white shadow-drop-shadow-light bg-primary-3 rounded-4 p-5 rem:min-h-[200px] xl:rem:w-[200px]
	.icon
		@apply size-20 mx-auto
	.title
		@apply body-18 font-normal text-white
	&:nth-child(2)
		@apply bg-primary-6
	&:nth-child(3)
		@apply bg-primary-4
	&:nth-child(4)
		@apply bg-primary-2

.easy-2-list
	@apply grid grid-cols-2 gap-5 md:grid-cols-4 xl:rem:max-w-[860px]

.easy-2-section
	@apply xl:pb-0 xl:rem:min-h-[720px]
	.image
		@apply rounded-4 aspect-[880/640] rem:max-w-[880px] mx-auto xl:rounded-none xl:rounded-t-4
	.site-desc
		@apply rem:max-w-[680px]
	.container
		@apply pointer-events-none
	@screen xl
		.image
			@apply absolute bottom-0 right-0 -z-1

.box-easy-3
	@apply spacing-mobile bg-white xl:p-10
	.swiper
		@apply rem:max-w-[920px] mx-auto
		.image
			@apply aspect-[920/640]
	@screen lg
		.swiper-button
			> *
				@apply absolute top-1/2 -translate-y-1/2 z-1
			.button-prev
				@apply left-5
			.button-next
				@apply right-5
	@screen xl
		.swiper-button
			.button-prev
				@apply rem:left-[72px]
			.button-next
				@apply rem:right-[72px]

.easy-3-item
	@apply flex flex-col gap-3
	.image
		@apply aspect-[320/200] rounded-[8px] xl:rounded-4
	.title
		@apply sub-header-20 font-semibold text-primary-2 text-center underline

.easy-3-section
	.table-shareholder-download
		td
			&:nth-child(1)
				@apply w-10 text-neutral-500 text-center pr-0
			&:nth-child(2)
				@apply pl-5
				a
					@apply underline
	.four-slider
		.swiper
			@apply overflow-visible

.easy-4-section
	.iframe-scale
		@apply pt-[calc(700/1400*100%)]

.easy-5-top-item
	@apply flex flex-col gap-4 p-4 rounded-4 bg-white mx-auto
	.image
		@apply aspect-[272/160] rounded-2
	.title
		@apply sub-header-20 font-bold text-primary-2 text-center underline

.easy-5-top-list
	@apply grid grid-cols-3 gap-6

.easy-5-bot-item
	@apply rem:px-[10px] py-5 flex justify-center gap-3 xl:gap-5
	.item
		@apply text-center
	img
		@apply h-16 object-contain
	figcaption
		@apply body-14 font-normal text-white text-center mt-2

.easy-5-bot-list
	@screen lg
		@apply flex justify-center gap-10
	@screen xl
		@apply gap-20
		> * + *
			@apply relative
			&::before
				content: ''
				@apply absolute top-1/2 -translate-y-1/2 -left-10 w-px h-20 bg-white/40 pointer-events-none

.easy-5-section
	@apply overflow-hidden xl:pb-22
	.auto-slider
		.swiper
			@apply overflow-visible
	.easy-5-top-list
		@apply rem:max-w-[960px] mx-auto

.easy-6-item
	.icon
		@apply size-30 bg-white rounded-4 flex-center

.easy-6-list
	@apply flex-center flex-wrap gap-5 xl:gap-10
