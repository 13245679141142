.footer-info
	@apply text-center text-[16px] font-normal leading-snug xl:text-base
	h4
		@apply text-[24px] font-bold leading-tight mb-[24px] xl:text-32 xl:mb-6
	ul
		@apply space-y-[4px] mt-[16px] xl:mt-2
	a
		@apply hover:text-primary-3
	@screen xl
		ul
			@apply flex-center gap-4 space-y-0
			li
				@apply flex items-center
				& + li
					&::before
						content: ''
						@apply inline-block rem:size-[3px] bg-white mr-4

.footer-links
	@apply flex flex-wrap items-center mt-[12px]
	a
		@apply flex items-center gap-[8px] text-[16px] font-normal leading-snug xl:text-base xl:gap-2
		@apply first:hidden last:after:hidden md:first:flex
		span
			@apply hover-underline
		&::after
			content: ''
			@apply w-px h-[12px] bg-white/40 mx-[20px] xl:mx-5
		&.current-menu-item
			@apply text-white
			span
				@apply hover-underline-active

.footer-bot
	@apply mt-[20px] text-[14px] font-normal leading-tight py-[8px] xl:py-5 xl:rem:mt-[140px] xl:text-base
	@screen md
		@apply flex items-center justify-between

footer
	@apply relative z-1 overflow-hidden pt-[32px] pb-[64px] bg-primary-2 text-white xl:pt-20 xl:pb-5
	.footer-bg
		@apply absolute bottom-0 -z-1 left-0 w-full rem:h-[200px] aspect-[1920/200] pointer-events-none
		@apply hidden xl:block
