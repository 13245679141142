.site-links
	@apply flex items-center overflow-x-auto gap-3 xl:justify-center xl:overflow-visible
	a
		@apply flex-center gap-3 body-16 font-medium text-neutral-950 border border-neutral-200 bg-neutral-100 rounded-full h-11 px-5 py-2 whitespace-nowrap
		@apply hover:bg-primary-1 hover:text-white hover:border-primary-1
	li
		@apply w-max
		&.active
			a
				@apply bg-primary-1 text-white border-primary-1

.foa-detail-2-dealer-1
	.block-content
		@apply rem:max-w-[960px]
	.block-image
		@apply aspect-[1400/700]
	.block-iframe
		.iframe-scale
			@apply pt-[calc(700/1400*100%)]
	.block-foa-dealer-3-image
		@apply aspect-[1400/700] rounded-4

.foa-dealer-2-item
	@apply p-4 bg-secondary-1 rounded-4 xl:p-10 xl:pt-6
	table
		@apply w-full
	tr
		@apply pb-1
	th
		@apply sub-header-24 font-bold text-primary-2 text-left py-3 pr-3 border-b border-primary-3 xl:pr-5 xl:rem:py-[15px]
		@apply last:text-right last:pr-0
	td
		@apply body-18 font-normal text-primary-2 text-left py-2 pr-3 border-b border-neutral-200 xl:pr-5 xl:py-3
		@apply last:text-right last:pr-0

.foa-dealer-2-list
	@apply grid grid-cols-1 gap-10 lg:grid-cols-2

.foa-dt-2-2-section
	.swiper-slide
		@apply w-[320px]

.foa-dealer-4-item
	@apply flex flex-col bg-white rounded-4 overflow-hidden border border-[#eee] cursor-pointer
	.image
		@apply aspect-[440/200]
	.caption
		@apply flex-1 bg-neutral-50 p-5 tsn xl:px-6
	.news-date
		@apply mb-2
	.title
		@apply my-2 sub-header-20 font-bold text-primary-2 line-clamp-2
	&:hover
		.caption
			@apply bg-neutral-100

.foa-dealer-4-list
	@apply grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3
