+form-text
	@apply bg-white border border-neutral-200 pl-5 pr-10 py-2 rounded-1 body-18 text-neutral-500 w-full bg-no-repeat h-12 m-0
	@apply focus:outline-none focus:ring-2 focus:ring-primary-2 focus:ring-offset-0

+form-input
	@apply placeholder:text-neutral-500
	// @apply placeholder-shown:bg-white
	@apply disabled:bg-neutral-700/50 disabled:border-neutral-700/75 disabled:text-neutral-900
	@apply read-only:bg-neutral-700/50 read-only:border-neutral-700/75 read-only:text-neutral-900

select
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%2300387A' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 8l4 4 4-4'/></svg>")
	-webkit-appearance: none
	-moz-appearance: none
	background-size: calc(32/1920*100rem)
	@apply rounded-full h-10 border border-current bg-transparent pl-4 pr-9 body-16 font-medium text-primary-2 bg-no-repeat bg-right
	&::-ms-expand
		@apply hidden

select[multiple]
	@apply min-h-[calc(48/1920*100rem)] py-3 h-auto bg-none

textarea
	@apply h-25 py-3 xl:rem:h-[136px]

input[type='file']
	@apply hidden
	~ label
		@apply relative w-max bg-neutral-600 border-none h-12 px-6 py-3 text-base text-primary-1 font-normal flex-center gap-3 cursor-pointer m-0 transition-all duration-200 ease-linear hover:bg-neutral-700

input[type='checkbox']
	@apply hidden
	~ label
		@apply relative pl-5
		&::before
			@apply font-awesome content-['\f0c8'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal
	&:checked
		~ label
			&::before
				@apply content-['\f14a']

input[type='radio']
	@apply hidden
	~ label
		@apply relative pl-5
		&::before
			@apply font-awesome content-['\f111'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal
	&:checked
		~ label
			&::before
				@apply content-['\f192']

.form-group
	@apply relative
	> label
		@apply relative block text-base font-normal leading-normal text-neutral-800
	[class*='fa-ex']
		@apply text-[12px] text-red-700 italic block mt-1 font-sans font-normal

.qradio

.qcheckbox

.qselect

.qnote

// button submit
.frm-btnwrap
	label.label, .frm-btn-reset
		@apply hidden
	input[type='submit']

// captcha
.frm-captcha
	@apply flex flex-wrap -mt-5 lg:flex-row-reverse lg:justify-end
	> div
		@apply mt-5
	label
		@apply hidden
	.frm-captcha-input
		@apply w-full lg:w-auto
		input
			@apply w-full xl:max-w-[calc(260/1920*100rem)]
	.RadCaptcha
		@apply flex flex-col-reverse
		@apply w-max
		> div
			@apply w-full
			> div
				@apply flex items-center
		> span
			@apply text-[12px] text-primary-2 italic block mt-1 font-sans font-normal
	.rcRefreshImage
		font-size: 0
		@apply before:font-awesome before:content-['\f2ea'] before:text-2xl before:text-blue-500 before:mx-5 xl:before:text-[calc(22/1920*100rem)] xl:before:ml-[calc(25.5/1920*100rem)] xl:before:mr-[calc(19.5/1920*100rem)]

.RadUpload
	@apply max-w-full
	input[type="file"]
		@apply block
	.ruInputs
		.ruRemove
			@apply text-0 w-auto
		li:not(:last-child)
			@apply flex items-center
		li
			&:last-child
				@apply mb-0
				.ruFileWrap
					@apply block w-full h-25 rounded-3 border border-dashed border-neutral-700 bg-white p-3 flex-center
					input
						@apply w-full h-full bg-white border-none text-main text-center

.apply-frm
	@apply p-5
	.form-group
		@apply mb-8 last:mb-0
		label
			@apply text-base w-full text-main font-normal mb-1
			span
				@apply text-primary-2 text-sm
		input:not([type="button"]), textarea
			@apply border-neutral-700
	.frm-btn
		@apply w-full flex justify-end
	@screen lg
		@apply py-8 px-10
		.form-group
			@apply flex gap-8
			label
				@apply w-[30%] mb-0 text-base
			input:not([type="button"])
				@apply h-10 text-base
			textarea
				@apply h-25 text-base
