.global-breadcrumb
	@apply bg-neutral-50
	.breadcrumb
		@apply flex flex-wrap items-center rem:py-[11px] gap-x-3 gap-y-2 xl:gap-x-4
		a
			@apply block text-[12px] leading-[calc(18/14)] font-normal text-neutral-500 xl:text-sm
		li
			@apply flex items-center last:after:hidden
			&::after
				@apply pl-3 font-awesome content-['\f054'] text-neutral-500 text-[12px] leading-none xl:pl-4 xl:text-xs
			&:first-child
				a
					@apply text-0
					span
						@apply hidden
					&::before
						@apply font-awesome content-['\f015'] text-neutral-500 text-[12px] font-normal leading-none flex-center xl:text-xs
			&:last-child
				a
					@apply text-neutral-950

	// .rank-math-breadcrumb
	// 	@apply py-2 xl:rem:py-[10px]
	// 	p
	// 		@apply flex flex-wrap items-center gap-2 xl:gap-4
	// 		> *
	// 			@apply body-16 font-normal text-neutral-400
	// 			&:first-child
	// 				@apply text-0
	// 				&::before
	// 					@apply font-awesome content-['\e487'] text-neutral-400 text-base font-thin leading-none flex-center
	// 		a
	// 			@apply hover:text-primary-1
	// 	.separator
	// 		@apply text-0
	// 		&::after
	// 			content: '|'
	// 			@apply text-base font-normal text-neutral-400 leading-none
