.home-news-bot
	@apply mt-[20px] pt-[20px] border-t border-neutral-200 xl:pt-6 xl:mt-6

.home-news-top
	@apply mt-[20px] xl:mt-9

.swiper-news-item
	@apply w-[320px] xl:w-[320px]

.block-news-category
	@apply flex flex-col gap-[20px] xl:gap-5
	.box-head
		@apply text-[20px] font-semibold text-primary-4 leading-1.3 w-full justify-between py-[4px] border-b border-primary-4 xl:py-1 xl:text-xl
		@apply hover:text-primary-4
		.icon
			@apply justify-start size-[40px] flex-center text-[18px] text-primary-4 xl:text-lg xl:size-10
			> *
				@apply h-full w-full min-w-full
	.box-image
		@apply aspect-[320/160] rounded-[8px] block xl:rounded-4
		img
			@apply tsn
		&:hover
			img
				@apply scale-110

.home-news-list
	@apply grid grid-cols-2 gap-10

.group-news-category
	@apply grid grid-cols-2 gap-10

.group-news-category-mobile
	@apply mt-[52px]
	.block-news-category
		@apply relative
		.box-head
			background: linear-gradient(90deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.90) 50%, rgba(255, 255, 255, 0.30) 100%)
			@apply absolute top-1/2 -translate-y-1/2 left-0 w-full z-1 min-h-[80px] px-5 justify-center gap-[4px] text-[18px] text-primary-1 font-bold border-none
			.icon
				@apply text-primary-1

.home-news
	@apply overflow-hidden pb-[40px] xl:pt-18 xl:rem:pb-[66px] lg:overflow-visible
	.block-content
		@apply sticky top-25 xl:rem:max-w-[600px]
	.auto-slider
		.swiper
			@apply overflow-visible lg:overflow-hidden
	.block-news-category
		@screen xl
			&:first-child
				@apply mt-11
	.group-news-category
		.block-news-category
			@screen xl
				&:first-child
					@apply mt-0

.home-gas-price
	@apply pb-[30px] xl:bg-primary-2 xl:pb-0 xl:rem:min-h-[174px] xl:flex xl:items-center

.home-3-item
	@apply flex flex-col gap-[16px] xl:gap-2
	.image
		@apply aspect-[320/200] rounded-[8px] xl:rounded-4
		img
			@apply tsn
	.caption
		@apply flex-1 pb-[18px] border-b border-primary-5 xl:pb-0
	.title
		@apply text-[18px] font-normal text-black leading-[1.3] hover:text-primary-2 xl:text-lg xl:min-h-15 xl:flex xl:items-center xl:py-4
	&:hover
		.image
			img
				@apply scale-110
		.title
			@apply text-primary-2
	@screen xl
		&:nth-child(1)
			.title
				@apply text-2xl font-bold

.home-3-list
	@apply grid grid-cols-3 gap-x-10 gap-y-8
	.home-3-item
		.image
			@apply aspect-[440/280]
		&:nth-child(1)
			@apply row-span-2 col-span-2 col-start-2 row-start-1
			.image
				@apply aspect-[920/658]
		&:nth-child(2)
			@apply col-start-1 row-start-1
		&:nth-child(3)
			@apply row-start-2

.home-3-section
	@apply pb-[38px] overflow-hidden xl:pb-20
	.swiper-relative
		@apply mt-[16px]
	.swiper
		@apply overflow-visible

.home-4-item
	@apply flex flex-col items-center gap-[12px] bg-secondary-1 rounded-[8px] p-[20px] text-center min-h-[140px] xl:p-6 xl:rounded-4 xl:gap-3 xl:rem:min-h-[148px] xl:justify-center
	.sub-title
		@apply text-[16px] font-normal text-neutral-500 leading-snug
	.title
		@apply text-[24px] font-bold text-primary-2 leading-snug xl:text-32
	&:first-child
		.title
			@apply text-[16px] xl:text-lg
	&:last-child
		.sub-title
			@apply max-w-[80px] md:max-w-full

.home-4-list
	@apply grid grid-cols-2 gap-[12px] lg:grid-cols-4 xl:gap-10 xl:grid-cols-2

.home-4-left
	.iframe-scale
		@apply pt-[calc(500/358*100%)] md:pt-[calc(500/680*100%)] lg:pt-[calc(350/680*100%)] xl:pt-[calc(800/680*100%)]
		@media only screen and (min-width: 1366px)
			@apply pt-[calc(690/680*100%)]
		@media only screen and (min-width: 1600px)
			@apply pt-[calc(590/680*100%)]
		@media only screen and (min-width: 1900px)
			@apply pt-[calc(500/680*100%)]

.home-4-section
	.site-title-large
		@apply mb-[16px] xl:mb-10

.home-5-list
	@apply grid grid-cols-4 gap-10
	> *
		&:nth-child(1)
			@apply col-span-2 row-span-3
		&:nth-child(2)
			@apply col-start-1 row-start-4
		&:nth-child(3)
			@apply col-start-2 row-start-4
		&:nth-child(4)
			@apply col-start-3 row-start-1
		&:nth-child(5)
			@apply col-start-4 row-start-1
		&:nth-child(6)
			@apply col-start-3 row-start-2
		&:nth-child(7)
			@apply col-start-4 row-start-2
		&:nth-child(8)
			@apply col-start-3 row-start-3
		&:nth-child(9)
			@apply col-start-4 row-start-3
		&:nth-child(10)
			@apply row-start-4
		&:nth-child(11)
			@apply row-start-4

.home-5-mb-list
	@apply -mx-[16px] px-[16px] mt-[16px] overflow-x-auto overflow-y-hidden grid grid-flow-col grid-rows-2 gap-[16px]
	> *
		@apply w-[240px]

.home-5-section
	@apply overflow-hidden
	.image
		@apply aspect-[358/280] rounded-[8px] mt-[16px] lg:mt-0 xl:rounded-4 xl:aspect-[680/584]

.home-6-section
	@apply bg-neutral-50 overflow-hidden lg:bg-white
	.site-desc
		@apply text-[16px] text-neutral-950 mt-[16px] xl:hidden
	.swiper
		@apply overflow-visible

.home-7-section
	@apply md:rem:pt-[34px] md:pb-5
	.block-content
		@apply px-[16px] py-[32px] bg-gradient-4 rounded-[8px] xl:rounded-4 xl:p-10 xl:py-12
	.main-tabslet
		@apply mt-[16px] xl:mt-4
	.swiper-relative
		@apply mt-[20px]
	.swiper-slide
		@apply w-[296px]
	.tabslet-tabs
		@apply justify-start
	.media-list
		@apply grid grid-cols-1 gap-10 lg:grid-cols-2
		.media-item
			&:nth-child(n+2)
				@screen md
					@apply flex-row
					.image
						@apply rem:w-[360px] aspect-[360/240]
					.caption
						@apply py-5
					.title
						@apply sub-header-20
		@screen lg
			.media-item
				&:nth-child(1)
					@apply row-span-2

.home-8-section
	.foa-dt-2-2-list
		@apply xl:gap-y-20
	.foa-dt-2-2-item
		&:last-child
			.image
				@apply rounded-none xl:aspect-[680/480]
			.caption
				@apply bg-secondary-1

.home-9-section
	@apply relative
	.image
		@apply aspect-[390/252] md:aspect-[1920/720] xl:aspect-[1920/720]
	.block-content
		@apply px-[16px] py-[32px] bg-gradient-4 backdrop-blur-[6px] xl:px-9 xl:py-10
	.site-desc
		@apply mt-[16px] text-[16px] xl:text-xl xl:mt-5
	.button
		@apply mt-[16px] xl:mt-6
	@screen lg
		.block-content
			background: linear-gradient(180deg, rgba(21, 101, 174, 0.60) 0%, rgba(0, 174, 239, 0.60) 100%)
			@apply absolute top-10 left-1/2 -translate-x-1/2 z-1 rounded-4 rem:max-w-[800px] w-full
