.banner-child
	@apply relative z-1
	.image
		@apply aspect-[390/204] xl:aspect-[1920/720]
		&::before
			content: ''
			background: linear-gradient(81deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 56.83%)
			@apply absolute inset-0 z-1 pointer-events-none
	.caption
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.48) 100%)
		@apply absolute bottom-0 left-0 z-2 w-full py-5 pointer-events-none xl:py-12
	.banner-title
		@apply rem:text-[28px] font-semibold text-white pointer-events-auto md:rem:text-[36px] xl:rem:text-[64px]
	&.banner-no-caption
		.image
			@apply before:hidden
		.caption
			@apply hidden
