
.box-news-detail
	.site-title-large
		@apply text-black leading-[1.17]
	.news-detail-flex
		@apply flex items-center justify-between gap-5
	.social-list
		ul
			@apply flex items-center gap-3 xl:gap-6
		a
			@apply size-auto rounded-none bg-transparent bg-none body-16 font-normal text-primary-2 flex items-center gap-2
	.full-content
		@apply body-18 font-normal text-black leading-[1.33]
		p
			@apply rem:max-w-[920px] mx-auto
	@media only screen and (max-width: 575.98px)
		.site-title-large
			@apply text-32

.news-other-item
	.image
		@apply rounded-t-[8px] overflow-hidden xl:rounded-t-4
	.caption
		@apply flex flex-col gap-4 spacing-mobile bg-white relative -mt-4 xl:p-6
	.title
		@apply body-18 font-normal text-black leading-[1.33] hover:text-primary-2
	&:hover
		.title
			@apply text-primary-2

.magazine-section
	.full-content
		@apply rem:max-w-[1920px] mx-auto
