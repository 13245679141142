.foa-1-item
	@apply relative z-1 flex flex-col justify-between rem:gap-[14px] px-[16px] py-[12px] rounded-[8px] border border-black/8 tsn overflow-hidden xl:px-6 xl:py-5 xl:rounded-4
	&::before
		content: ''
		@apply absolute bottom-0 left-0 w-full h-0 bg-primary-2 tsn -z-1
	*
		@apply tsn
	.icon
		@apply size-[48px] xl:size-16
	.top
		@apply flex flex-row gap-[16px] md:flex-row xl:gap-4
	.caption
		@apply flex-1 flex flex-col
	.number
		@apply text-[32px] font-bold text-primary-2 leading-tight xl:text-40
	.suffix
		@apply text-[14px] font-normal text-primary-2 leading-[1.29] rem:-mt-1 xl:text-base
	.sub-number
		@apply text-[14px] font-normal text-primary-2 leading-[1.29] rem:-mt-1 xl:text-base
	.title
		@apply text-[16px] font-normal text-black leading-snug xl:text-lg
	&:hover
		@apply border-primary-2 before:h-full
		*
			@apply text-white

.foa-1-list
	@apply grid grid-cols-2 gap-5 md:grid-cols-4 xl:gap-10

.foa-item
	@apply rounded-4 overflow-hidden flex flex-col md:flex-row
	.caption
		@apply flex-1 flex flex-col justify-between backdrop-blur px-[24px] py-[20px] md:p-8 xl:p-12
	.title
		@apply text-[24px] font-bold text-white leading-tight xl:text-32
	.image
		@apply aspect-square md:aspect-[960/400] md:w-2/3 xl:rem:w-[960px]
		img
			@apply transition-all duration-1000 ease-in-out
		&:hover
			img
				@apply scale-110
	.button
		@apply mt-[42px] xl:mt-5

.foa-list
	@apply space-y-10

.foa-1-section
	.swiper-slide
		@apply w-[320px]

.foa-2-section
	.image
		@apply rem:max-w-[616px] mx-auto xl:ml-auto xl:mr-0
	.foa-1-list
		@apply grid-cols-2
	.block-content
		@apply xl:pt-20
