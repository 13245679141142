@charset "UTF-8";
/**
 * This injects Tailwind's base styles and any base styles registered-500 by
 * plugins.
 */
@tailwind base {}/**
 * This injects Tailwind's component classes and any component classes
 * registered-500 by plugins.
 */
@tailwind components {}/**
 * This injects Tailwind's utility classes and any utility classes registered-500
 * by plugins.
 */
@tailwind utilities {}/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants {}.edit-link i:before {
  content: '✏️'; }

.header-64 {
  @apply text-[32px] leading-normal xl:text-64 {} }

.header-48 {
  @apply text-[32px] leading-snug xl:text-5xl {} }

.header-40 {
  @apply text-[28px] leading-snug xl:text-40 {} }

.section-header-32 {
  @apply text-[24px] leading-tight xl:text-32 {} }

.sub-header-24 {
  @apply text-[20px] leading-1.3 xl:text-2xl xl:leading-tight {} }

.sub-header-20 {
  @apply text-[18px] leading-1.3 xl:text-xl xl:leading-tight {} }

.body-18 {
  @apply text-[16px] leading-snug xl:text-lg xl:leading-1.33 {} }

.body-16 {
  @apply text-[16px] leading-snug xl:text-base xl:leading-1.33 {} }

.body-14 {
  @apply text-[14px] leading-tight xl:text-sm {} }

.label-12 {
  @apply text-[12px] leading-snug xl:text-sm {} }

.site-title-large {
  @apply header-48 font-bold text-primary-2 {} }
  .site-title-large.is-white {
    @apply text-white {} }
  .site-title-large.is-red {
    @apply text-primary-1 {} }
  .site-title-large.is-primary-3 {
    @apply text-primary-3 {} }

.site-title {
  @apply header-40 font-bold text-primary-2 {} }
  .site-title.is-white {
    @apply text-white {} }
  .site-title.is-red {
    @apply text-primary-1 {} }
  .site-title.is-primary-3 {
    @apply text-primary-3 {} }

.site-sub-title {
  @apply section-header-32 font-normal text-primary-2 {} }
  .site-sub-title.is-white {
    @apply text-white {} }

.site-desc {
  @apply body-18 font-normal text-black {} }
  .site-desc.is-white {
    @apply text-white {} }

.site-full {
  @apply body-16 font-normal text-black {} }
  .site-full.is-white {
    @apply text-white {} }

.full-content {
  @apply prose max-w-none {}  @apply body-16 font-normal text-main {} }
  .full-content > * {
    @apply first:mt-0 last:mb-0 {} }
  .full-content p img {
    @apply m-0 {} }
  .full-content ul {
    @apply list-disc pl-4 {} }
  .full-content ol {
    @apply list-decimal pl-4 {} }

@keyframes ctaPhoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-15deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(15deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-15deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(15deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

.button {
  @apply flex flex-wrap gap-5 {} }

.link {
  @apply flex-center gap-2 body-16 font-medium text-primary-2 {}  @apply hover:text-primary-2 {} }
  .link .icon {
    @apply text-lg rem:h-[30px] rem:w-[18px] flex items-center overflow-hidden {} }
    .link .icon > * {
      @apply rem:h-[30px] rem:w-[18px] rem:min-w-[18px] flex-center tsn -translate-x-full {} }
  .link:hover .icon > * {
    @apply translate-x-0 {} }
  .link.is-white {
    @apply text-white {}    @apply hover:text-white {} }
  .link.is-light-blue {
    @apply text-primary-3 {}    @apply hover:text-primary-3 {} }
  .link.is-download {
    @apply gap-3 {} }
    .link.is-download .icon {
      @apply size-10 border-2 border-current rounded-full flex flex-col items-start justify-start {} }
      .link.is-download .icon > * {
        @apply size-full min-h-full translate-x-0 -translate-y-full {} }
    .link.is-download:hover .icon > * {
      @apply translate-y-0 {} }

.btn-solid {
  @apply flex-center gap-2 py-2 px-4 min-h-12 bg-primary-1 sub-header-20 font-semibold text-white rounded-full tsn xl:px-6 {}  @apply hover:text-white {} }
  .btn-solid .icon {
    @apply text-lg rem:h-[30px] rem:w-[18px] flex items-center overflow-hidden {} }
    .btn-solid .icon > * {
      @apply rem:h-[30px] rem:w-[18px] rem:min-w-[18px] flex-center tsn -translate-x-full {} }
  .btn-solid:hover {
    box-shadow: 0px 0px 8px 0px theme("colors.primary.1"); }
    .btn-solid:hover .icon > * {
      @apply translate-x-0 {} }
  .btn-solid.is-white {
    @apply bg-white text-primary-1 {}    @apply hover:text-primary-1 {} }

.btn-lined {
  @apply flex-center gap-2 py-2 px-4 min-h-12 border border-primary-1 sub-header-20 font-semibold text-primary-1 rounded-full xl:px-6 {}  @apply hover:text-primary-1 {} }
  .btn-lined .icon {
    @apply text-lg rem:h-[30px] rem:w-[18px] flex items-center overflow-hidden {} }
    .btn-lined .icon > * {
      @apply rem:h-[30px] rem:w-[18px] rem:min-w-[18px] flex-center tsn -translate-x-full {} }
  .btn-lined:hover .icon > * {
    @apply translate-x-0 {} }
  .btn-lined.is-white {
    @apply border-white text-white {}    @apply hover:text-white {} }
  .btn-lined.is-blue {
    @apply border-primary-2 text-primary-2 {}    @apply hover:text-primary-2 {} }
  .btn-lined.is-icon {
    @apply min-h-0 p-0 size-[40px] min-w-[40px] overflow-hidden xl:min-w-10 xl:size-10 {} }
    .btn-lined.is-icon .icon {
      @apply h-full {} }
      .btn-lined.is-icon .icon > * {
        @apply h-full {} }
    .btn-lined.is-icon.is-large-icon {
      @apply xl:size-16 {} }
      .btn-lined.is-icon.is-large-icon .icon {
        @apply xl:text-xl {} }

#buttonMenu {
  @apply relative z-50 min-w-[48px] size-[48px] rounded-[8px] bg-primary-1 flex-center {} }
  #buttonMenu::before {
    @apply font-awesome content-['\f0c9'] text-[24px] font-normal text-white {} }
  #buttonMenu.active::before {
    @apply content-['\f00d'] {} }

@screen xl {
  #buttonMenu {
    display: none !important; } }

.alert {
  @apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm {} }
  .alert button {
    @apply hidden {} }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.backdrop {
  @apply fixed z-50 size-full top-0 left-0 bg-black/75 backdrop-blur hidden {} }
  .backdrop.backdrop-mobile {
    @apply top-[60px] xl:top-0 {} }

.cta-fixed {
  @apply fixed z-50 rem:bottom-[102px] right-0 pointer-events-none hidden xl:block {} }
  .cta-fixed li {
    @apply pt-1 {} }
  .cta-fixed a {
    @apply flex items-center gap-2 bg-primary-3 text-base font-medium text-white shadow-drop-shadow-light pointer-events-auto {}    @apply translate-x-[calc(100%-(48/1920*100rem))] {}    @apply hover:bg-primary-4 hover:pointer-events-auto hover:translate-x-0 {} }
  .cta-fixed .icon {
    @apply size-12 flex-center text-2xl {} }
  .cta-fixed .text {
    @apply pr-2 {} }

.cta-fixed-2 {
  @apply fixed z-40 bottom-[50px] right-[20px] flex flex-col gap-[8px] xl:right-25 xl:bottom-8 {} }
  .cta-fixed-2 .text {
    @apply hidden text-sm font-normal text-white whitespace-nowrap xl:block {} }
  .cta-fixed-2 .icon {
    @apply size-[32px] text-[24px] flex flex-col items-center rounded-full border border-white overflow-hidden xl:size-8 xl:text-2xl {} }
    .cta-fixed-2 .icon > * {
      @apply size-[32px] min-h-[32px] flex-center tsn xl:size-8 xl:min-h-8 {} }
  .cta-fixed-2 > * {
    @apply size-[40px] rounded-full flex-center text-white text-[24px] bg-primary-3 cursor-pointer tsn xl:h-10 xl:gap-3 xl:w-max xl:p-1 xl:pr-3 xl:text-2xl xl:bg-primary-4 {} }
    .cta-fixed-2 > *:hover .icon > * {
      @apply -translate-y-[32px] xl:-translate-y-8 {} }
    .cta-fixed-2 > *.gas-pump {
      @apply bg-primary-1 hover:text-white xl:hidden {} }

.social-list ul {
  @apply flex flex-wrap gap-2 {} }

.social-list a {
  @apply w-10 h-10 rounded-full flex-center text-white text-base bg-primary-2 hover:bg-neutral-900 {} }

.mobile-menu-fixed {
  @apply fixed bottom-0 left-0 w-full bg-primary-1 z-40 md:hidden {} }
  .mobile-menu-fixed ul {
    @apply flex-center {} }
  .mobile-menu-fixed li {
    @apply px-[14px] relative {} }
    .mobile-menu-fixed li + li::before {
      content: '';
      @apply absolute top-1/2 -translate-y-1/2 w-[1px] h-[24px] bg-white/50 left-0 {} }
  .mobile-menu-fixed a {
    @apply flex-center gap-[12px] text-[16px] text-center text-white font-normal h-[44px] {} }
    .mobile-menu-fixed a i {
      @apply text-[20px] {} }

.dropdown {
  @apply relative {} }
  .dropdown .dropdown-toggle {
    @apply text-neutral-700 flex items-center justify-between gap-4 cursor-pointer {} }
  .dropdown .dropdown-menu {
    @apply absolute top-full mt-5 left-0 w-full min-w-max z-10 bg-white rounded-1 px-5 py-3 shadow opacity-0 pointer-events-none transition-all duration-200 ease-linear {} }
  .dropdown.open .dropdown-toggle i, .dropdown.open .dropdown-toggle em {
    @apply rotate-180 {} }
  .dropdown.open .dropdown-menu {
    @apply opacity-100 pointer-events-auto {} }

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
textarea {
  @apply bg-white border border-neutral-200 pl-5 pr-10 py-2 rounded-1 body-18 text-neutral-500 w-full bg-no-repeat h-12 m-0 {}  @apply focus:outline-none focus:ring-2 focus:ring-primary-2 focus:ring-offset-0 {} }

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'] {
  @apply placeholder:text-neutral-500 {}  @apply disabled:bg-neutral-700/50 disabled:border-neutral-700/75 disabled:text-neutral-900 {}  @apply read-only:bg-neutral-700/50 read-only:border-neutral-700/75 read-only:text-neutral-900 {} }

select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%2300387A' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 8l4 4 4-4'/></svg>");
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: calc(32/1920*100rem);
  @apply rounded-full h-10 border border-current bg-transparent pl-4 pr-9 body-16 font-medium text-primary-2 bg-no-repeat bg-right {} }
  select::-ms-expand {
    @apply hidden {} }

select[multiple] {
  @apply min-h-[calc(48/1920*100rem)] py-3 h-auto bg-none {} }

textarea {
  @apply h-25 py-3 xl:rem:h-[136px] {} }

input[type='file'] {
  @apply hidden {} }
  input[type='file'] ~ label {
    @apply relative w-max bg-neutral-600 border-none h-12 px-6 py-3 text-base text-primary-1 font-normal flex-center gap-3 cursor-pointer m-0 transition-all duration-200 ease-linear hover:bg-neutral-700 {} }

input[type='checkbox'] {
  @apply hidden {} }
  input[type='checkbox'] ~ label {
    @apply relative pl-5 {} }
    input[type='checkbox'] ~ label::before {
      @apply font-awesome content-['\f0c8'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal {} }
  input[type='checkbox']:checked ~ label::before {
    @apply content-['\f14a'] {} }

input[type='radio'] {
  @apply hidden {} }
  input[type='radio'] ~ label {
    @apply relative pl-5 {} }
    input[type='radio'] ~ label::before {
      @apply font-awesome content-['\f111'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal {} }
  input[type='radio']:checked ~ label::before {
    @apply content-['\f192'] {} }

.form-group {
  @apply relative {} }
  .form-group > label {
    @apply relative block text-base font-normal leading-normal text-neutral-800 {} }
  .form-group [class*='fa-ex'] {
    @apply text-[12px] text-red-700 italic block mt-1 font-sans font-normal {} }

.frm-btnwrap label.label, .frm-btnwrap .frm-btn-reset {
  @apply hidden {} }

.frm-captcha {
  @apply flex flex-wrap -mt-5 lg:flex-row-reverse lg:justify-end {} }
  .frm-captcha > div {
    @apply mt-5 {} }
  .frm-captcha label {
    @apply hidden {} }
  .frm-captcha .frm-captcha-input {
    @apply w-full lg:w-auto {} }
    .frm-captcha .frm-captcha-input input {
      @apply w-full xl:max-w-[calc(260/1920*100rem)] {} }
  .frm-captcha .RadCaptcha {
    @apply flex flex-col-reverse {}    @apply w-max {} }
    .frm-captcha .RadCaptcha > div {
      @apply w-full {} }
      .frm-captcha .RadCaptcha > div > div {
        @apply flex items-center {} }
    .frm-captcha .RadCaptcha > span {
      @apply text-[12px] text-primary-2 italic block mt-1 font-sans font-normal {} }
  .frm-captcha .rcRefreshImage {
    font-size: 0;
    @apply before:font-awesome before:content-['\f2ea'] before:text-2xl before:text-blue-500 before:mx-5 xl:before:text-[calc(22/1920*100rem)] xl:before:ml-[calc(25.5/1920*100rem)] xl:before:mr-[calc(19.5/1920*100rem)] {} }

.RadUpload {
  @apply max-w-full {} }
  .RadUpload input[type="file"] {
    @apply block {} }
  .RadUpload .ruInputs .ruRemove {
    @apply text-0 w-auto {} }
  .RadUpload .ruInputs li:not(:last-child) {
    @apply flex items-center {} }
  .RadUpload .ruInputs li:last-child {
    @apply mb-0 {} }
    .RadUpload .ruInputs li:last-child .ruFileWrap {
      @apply block w-full h-25 rounded-3 border border-dashed border-neutral-700 bg-white p-3 flex-center {} }
      .RadUpload .ruInputs li:last-child .ruFileWrap input {
        @apply w-full h-full bg-white border-none text-main text-center {} }

.apply-frm {
  @apply p-5 {} }
  .apply-frm .form-group {
    @apply mb-8 last:mb-0 {} }
    .apply-frm .form-group label {
      @apply text-base w-full text-main font-normal mb-1 {} }
      .apply-frm .form-group label span {
        @apply text-primary-2 text-sm {} }
    .apply-frm .form-group input:not([type="button"]), .apply-frm .form-group textarea {
      @apply border-neutral-700 {} }
  .apply-frm .frm-btn {
    @apply w-full flex justify-end {} }

@screen lg {
  .apply-frm {
    @apply py-8 px-10 {} }
    .apply-frm .form-group {
      @apply flex gap-8 {} }
      .apply-frm .form-group label {
        @apply w-[30%] mb-0 text-base {} }
      .apply-frm .form-group input:not([type="button"]) {
        @apply h-10 text-base {} }
      .apply-frm .form-group textarea {
        @apply h-25 text-base {} } }

.modal {
  @apply bg-transparent bg-none p-0 {} }
  .modal .modal-wrap {
    @apply bg-white p-5 rounded-2 shadow {} }

.pagination {
  @apply flex-center flex-wrap gap-3 mt-10 xl:mt-12 xl:gap-5 {} }
  .pagination span, .pagination a {
    @apply body-16 font-medium text-neutral-500 tsn flex items-center min-w-3 border-b border-transparent {}    @apply hover:text-primary-2 {} }
  .pagination li.active span, .pagination li.active a {
    @apply text-primary-2 border-primary-3 {} }
  .pagination .prev a, .pagination .next a {
    @apply size-10 flex-center border border-primary-2 rounded-full text-lg text-primary-2 tsn hover:bg-primary-2 hover:text-white {} }
  .pagination .prev.disabled a, .pagination .next.disabled a {
    @apply opacity-20 pointer-events-none {} }

.custom-scroll-dark::-webkit-scrollbar {
  width: 14px;
  height: 14px; }

.custom-scroll-dark::-webkit-scrollbar-button {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-track {
  background-color: #646464 !important; }

.custom-scroll-dark::-webkit-scrollbar-track-piece {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #242424 !important;
  border: 2px solid #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(130deg, #696969 40%, rgba(255, 0, 0, 0) 41%), linear-gradient(230deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(0deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(310deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(50deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(180deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(330deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(90deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(150deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(270deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.swiper-pagination .swiper-pagination-bullet {
  @apply opacity-100 size-[8px] rounded-full bg-primary-2/50 mx-[6px] xl:size-2 xl:rem:mx-[6px] {} }
  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-primary-2 w-[24px] xl:w-6 {} }

.swiper-pagination.is-white {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.36) 100%);
  @apply bottom-0 left-0 py-[16px] {} }
  .swiper-pagination.is-white .swiper-pagination-bullet {
    @apply bg-white/50 {} }
    .swiper-pagination.is-white .swiper-pagination-bullet.swiper-pagination-bullet-active {
      @apply bg-white {} }

.swiper-button {
  @apply flex-center gap-3 {} }
  .swiper-button > * {
    @apply size-12 rounded-full flex-center border border-primary-2 text-primary-2 tsn bg-white {}    @apply hover:bg-primary-2 hover:text-white {} }
    .swiper-button > *.swiper-button-disabled {
      @apply opacity-40 {} }
    .swiper-button > *.swiper-button-lock {
      @apply opacity-0 pointer-events-none {} }
  .swiper-button.is-lined > * {
    @apply size-10 bg-none bg-transparent border border-primary-2 text-primary-2 rounded-full xl:size-12 xl:text-lg {} }
  .swiper-button.is-white > * {
    @apply text-white bg-white/20 border-white hover:text-neutral-200 {} }
  .swiper-button.is-abs > * {
    @apply absolute top-1/2 -translate-y-1/2 z-1 {} }
  .swiper-button.is-abs .button-prev {
    @apply -left-5 xl:-left-22 {} }
  .swiper-button.is-abs .button-next {
    @apply -right-5 xl:-right-22 {} }
  .swiper-button.is-abs.is-top-20 > * {
    @apply top-[20%] {} }
  .swiper-button.is-abs.is-top-30 > * {
    @apply top-[30%] {} }
  .swiper-button.is-abs.is-top-40 > * {
    @apply top-[40%] {} }

.swiper-relative {
  @apply xl:px-0 {} }
  .swiper-relative.is-page {
    @apply pb-10 lg:pb-0 {} }
  .swiper-relative .swiper-scrollbar {
    @apply h-[4px] bg-neutral-200 rounded-none left-0 w-full {} }
    .swiper-relative .swiper-scrollbar .swiper-scrollbar-drag {
      @apply bg-primary-1 hover:bg-primary-1 rounded-none {} }

@layer base {
  html, body {
    font-size: 16px;
    scroll-padding-top: 80px; }
  @screen xl {
    html, body {
      font-size: 1vw;
      @apply scroll-pt-25 {} } }
  body {
    @apply accent-blue-700 {}    @apply font-sans overflow-y-overlay text-main {} }
  main {
    @apply pt-[100px] xl:pt-25 {} }
  h1 {
    @apply text-3xl font-bold {} }
  h2 {
    @apply text-2xl font-bold {} }
  h3 {
    @apply text-xl font-bold {} }
  h4 {
    @apply text-lg font-bold {} }
  h5 {
    @apply text-base font-bold {} }
  h6 {
    @apply text-sm font-bold {} }
  a {
    @apply transition-all ease-linear duration-200 hover:text-current {} }
  img {
    @apply max-w-full inline {} }
  main, div {
    @apply xl:text-base {} }
  ::is(tbody, table) > tr:nth-child(odd) {
    background: #0001; }
  @media (prefers-reduced-motion) {
    *, *::before, *::after {
      animation-duration: 0s !important;
      /* additional recommendation */
      transition: none !important;
      scroll-behavior: auto !important; } } }

@layer components {
  @screen xs {
    .container {
      @apply max-w-full {} } }
  @screen sm {
    .container {
      @apply max-w-[96vw] {} } }
  @screen xl {
    .container {
      @apply max-w-[calc(1440/1920*100rem)] {} } }
  .equal-height .row > [class*=col] > * {
    @apply h-full {} }
  .equal-height .swiper-slide {
    @apply h-auto {} }
    .equal-height .swiper-slide > * {
      @apply h-full {} }
  .lozad-bg {
    @apply bg-center bg-no-repeat bg-cover {} }
  .img-cover {
    @apply relative overflow-hidden {} }
    .img-cover img {
      @apply w-full h-full object-cover {} }
  .img-contain {
    @apply relative overflow-hidden {} }
    .img-contain img {
      @apply w-full h-full object-contain {} }
  .item-hover .image {
    @apply relative overflow-hidden {} }
    .item-hover .image img {
      @apply tsn {} }
  .item-hover:hover .image img {
    @apply scale-110 {} } }

@layer utilities {
  .container-fuild {
    @apply px-4 {} }
  .section {
    @apply py-[32px] xl:py-15 {} }
  .section-t {
    @apply pt-[32px] xl:pt-15 {} }
  .section-b {
    @apply pb-[32px] xl:pb-15 {} }
  .section-small {
    @apply py-[28px] xl:py-10 {} }
  .section-t-small {
    @apply pt-[28px] xl:pt-10 {} }
  .section-b-small {
    @apply pb-[28px] xl:pb-10 {} }
  .section-large {
    @apply py-[32px] xl:py-20 {} }
  .section-t-large {
    @apply pt-[32px] xl:pt-20 {} }
  .section-b-large {
    @apply pb-[32px] xl:pb-20 {} }
  .row {
    @apply flex flex-wrap -mx-[16px] xl:-mx-5 {} }
    .row > * {
      @apply px-[16px] xl:px-5 {} }
  .no-gutters {
    @apply mx-0 {} }
    .no-gutters > * {
      @apply px-0 {} }
  .swiper-relative {
    @apply relative {} }
  .writing-mode-tb-rl {
    writing-mode: vertical-rl;
    text-orientation: mixed; }
  .media-scale {
    @apply relative block h-0 overflow-hidden {} }
    .media-scale img, .media-scale iframe, .media-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-500 ease-in-out {} }
  .img-scale {
    @apply relative block h-0 overflow-hidden {} }
    .img-scale img {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-500 ease-in-out object-cover {} }
  .iframe-scale {
    @apply relative block h-0 overflow-hidden {} }
    .iframe-scale iframe {
      @apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-500 ease-in-out {} }
  .video-scale {
    @apply relative block h-0 overflow-hidden {} }
    .video-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-500 ease-in-out object-cover {} }
  .bg-bright-grey {
    @apply backdrop-blur-25 {} }
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 {} }
  .filter-white {
    @apply brightness-0 invert {} }
  .img-zoom-in:hover img {
    @apply scale-110 {} }
  .hover-underline {
    @apply relative {}    background: linear-gradient(0deg, theme("colors.current"), theme("colors.current")) no-repeat right bottom/0 theme("spacing[px]");
    transition: background-size 350ms; }
    .hover-underline:hover {
      background-size: 100% theme("spacing[px]");
      background-position-x: left; }
  .hover-underline-active {
    background-size: 100% theme("spacing[px]");
    background-position-x: left; }
  .desktop-only {
    @apply hidden lg:block {} }
  .mobile-only {
    @apply block lg:hidden {} }
  .text-xs {
    @apply text-[14px] xl:text-[calc(12/1920*100rem)] {} }
  .tsn {
    @apply transition-all duration-500 ease-in-out {} }
  .spacing-mobile {
    @apply px-[16px] py-[32px] rounded-[8px] xl:rounded-4 {} } }

.global-breadcrumb {
  @apply bg-neutral-50 {} }
  .global-breadcrumb .breadcrumb {
    @apply flex flex-wrap items-center rem:py-[11px] gap-x-3 gap-y-2 xl:gap-x-4 {} }
    .global-breadcrumb .breadcrumb a {
      @apply block text-[12px] leading-[calc(18/14)] font-normal text-neutral-500 xl:text-sm {} }
    .global-breadcrumb .breadcrumb li {
      @apply flex items-center last:after:hidden {} }
      .global-breadcrumb .breadcrumb li::after {
        @apply pl-3 font-awesome content-['\f054'] text-neutral-500 text-[12px] leading-none xl:pl-4 xl:text-xs {} }
      .global-breadcrumb .breadcrumb li:first-child a {
        @apply text-0 {} }
        .global-breadcrumb .breadcrumb li:first-child a span {
          @apply hidden {} }
        .global-breadcrumb .breadcrumb li:first-child a::before {
          @apply font-awesome content-['\f015'] text-neutral-500 text-[12px] font-normal leading-none flex-center xl:text-xs {} }
      .global-breadcrumb .breadcrumb li:last-child a {
        @apply text-neutral-950 {} }

.searchbox {
  @apply relative container px-0 {} }
  .searchbox input {
    @apply w-full h-12 border-neutral-200 border rounded-1 pl-5 pr-12 text-base text-neutral-700 placeholder:text-neutral-200 focus:outline-none focus:border-primary-1 {} }
  .searchbox button {
    @apply absolute top-1/2 -translate-y-1/2 right-1 z-1 w-11 h-11 flex-center text-xl text-primary-2 {} }

.banner-child {
  @apply relative z-1 {} }
  .banner-child .image {
    @apply aspect-[390/204] xl:aspect-[1920/720] {} }
    .banner-child .image::before {
      content: '';
      background: linear-gradient(81deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 56.83%);
      @apply absolute inset-0 z-1 pointer-events-none {} }
  .banner-child .caption {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.48) 100%);
    @apply absolute bottom-0 left-0 z-2 w-full py-5 pointer-events-none xl:py-12 {} }
  .banner-child .banner-title {
    @apply rem:text-[28px] font-semibold text-white pointer-events-auto md:rem:text-[36px] xl:rem:text-[64px] {} }
  .banner-child.banner-no-caption .image {
    @apply before:hidden {} }
  .banner-child.banner-no-caption .caption {
    @apply hidden {} }

.home-banner {
  @apply relative {} }
  .home-banner .image {
    @apply aspect-[390/204] xl:aspect-[1920/960] {} }
    .home-banner .image .mb {
      @apply md:hidden {} }
      .home-banner .image .mb ~ img {
        @apply hidden md:block {} }
  .home-banner .quick-links {
    @apply hidden xl:block {} }

@screen xl {
  .home-banner .swiper-pagination {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.36) 100%);
    @apply py-19 {} } }
  .home-banner.banner-full .image {
    @apply w-screen h-[calc(100vh-100px)] aspect-none md:h-auto md:w-full md:aspect-[1920/960] {} }
    .home-banner.banner-full .image img {
      @apply size-full object-cover {} }

.banner-quick-links {
  @apply hidden absolute z-10 bottom-0 left-1/2 -translate-x-1/2 w-full rounded-t-4 overflow-hidden px-0 xl:flex-center {} }
  .banner-quick-links a {
    @apply flex-center sub-header-20 font-semibold text-white px-5 py-4 bg-primary-3 backdrop-blur opacity-70 tsn flex-grow h-16 {}    @apply hover:opacity-100 {} }
    .banner-quick-links a:nth-child(2) {
      @apply bg-primary-4 {} }
    .banner-quick-links a:nth-child(3) {
      @apply bg-primary-2 {} }

.site-nav-list {
  @apply flex overflow-x-auto h-full gap-3 xl:gap-5 {} }
  .site-nav-list::-webkit-scrollbar {
    @apply size-0 {} }
  .site-nav-list a {
    @apply flex items-center h-12 body-16 font-medium text-neutral-950 border-b border-transparent whitespace-nowrap {} }
  .site-nav-list li {
    @apply w-max flex items-center gap-3 last:after:hidden xl:gap-5 {} }
    .site-nav-list li::after {
      content: '';
      @apply w-px h-3 bg-neutral-200 {} }
    .site-nav-list li.active a, .site-nav-list li:hover a {
      @apply text-primary-2 border-primary-3 {} }

.tabslet-tabs {
  @apply flex items-center overflow-x-auto gap-3 xl:justify-center xl:overflow-visible {} }
  .tabslet-tabs a {
    @apply flex-center gap-3 body-16 font-medium text-neutral-950 border border-neutral-200 bg-neutral-100 rounded-full h-11 px-5 py-2 whitespace-nowrap {}    @apply hover:bg-primary-1 hover:text-white hover:border-primary-1 {} }
  .tabslet-tabs li {
    @apply w-max {} }
    .tabslet-tabs li.active a {
      @apply bg-primary-1 text-white border-primary-1 {} }

.site-sticky {
  @apply sticky top-[60px] z-40 bg-white overflow-hidden border-y border-neutral-100 xl:top-25 xl:z-50 {} }

.about-1-item {
  @apply bg-white {} }
  .about-1-item .year {
    @apply sub-header-24 font-bold mb-2 xl:hidden {} }

.about-1-circle-item {
  @apply pt-full rounded-full relative text-primary-2 text-center cursor-pointer {} }
  .about-1-circle-item::before {
    content: '';
    @apply absolute pointer-events-none {} }
  .about-1-circle-item::after {
    content: '';
    @apply absolute size-0 pointer-events-none {} }
  .about-1-circle-item * {
    @apply tsn {} }
  .about-1-circle-item .caption {
    @apply absolute-center size-full flex-center flex-col gap-1 p-2 z-2 rounded-full overflow-hidden xl:p-5 {} }
    .about-1-circle-item .caption::before {
      content: '';
      @apply absolute size-full -z-2 border-2 border-current pointer-events-none rounded-full {} }
    .about-1-circle-item .caption::after {
      content: '';
      @apply w-full h-0 absolute left-0 bottom-0 -z-1 bg-current pointer-events-none tsn rounded-full {} }
  .about-1-circle-item .year {
    @apply text-[10px] font-bold text-current md:text-2xl {} }
  .about-1-circle-item .title {
    @apply text-[10px] font-medium text-black md:text-base {} }
  .about-1-circle-item:hover .caption, .about-1-circle-item.active .caption {
    @apply after:h-full {} }
    .about-1-circle-item:hover .caption *, .about-1-circle-item.active .caption * {
      @apply text-white !important {} }
  .about-1-circle-item.is-dark-blue {
    @apply text-primary-2 {} }
  .about-1-circle-item.is-light-blue {
    @apply text-primary-3 {} }
  .about-1-circle-item.is-red {
    @apply text-primary-1 {} }
  .about-1-circle-item:nth-child(1)::before {
    @apply top-1/2 -translate-y-1/2 -right-5 w-5 h-[2px] bg-primary-2 md:w-10 md:-right-10 {} }
  .about-1-circle-item:nth-child(1)::after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 10px solid theme("colors.primary.2");
    @apply top-1/2 -translate-y-1/2 -right-5 md:-right-10 {} }
  .about-1-circle-item:nth-child(2)::before {
    @apply left-1/2 -translate-x-1/2 -bottom-5 h-5 w-[2px] bg-primary-3 md:h-10 md:-bottom-10 {} }
  .about-1-circle-item:nth-child(2)::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid theme("colors.primary.3");
    @apply left-1/2 -translate-x-1/2 -bottom-5 md:-bottom-10 {} }
  .about-1-circle-item:nth-child(3)::before, .about-1-circle-item:nth-child(4)::before {
    @apply top-1/2 -translate-y-1/2 -right-5 w-5 h-[2px] bg-primary-3 md:w-10 md:-right-10 {} }
  .about-1-circle-item:nth-child(3)::after, .about-1-circle-item:nth-child(4)::after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 10px solid theme("colors.primary.3");
    @apply top-1/2 -translate-y-1/2 -right-5 md:-right-10 {} }
  .about-1-circle-item:nth-child(4) .year {
    @apply text-primary-1 {} }
  .about-1-circle-item:nth-child(n+3) {
    @apply row-start-2 {} }

.about-1-circle-list {
  @apply grid grid-cols-3 gap-5 md:gap-10 {} }

.about-1-section .block-content {
  @apply xl:rem:max-w-[640px] xl:py-10 {} }

.about-1-section .about-1-slider {
  @apply xl:pb-0 {} }

.about-1-section .swiper-slide .about-1-item {
  @apply opacity-0 {} }

.about-1-section .swiper-slide.swiper-slide-active .about-1-item {
  @apply opacity-100 {} }

.about-2-section .image {
  @apply aspect-[1920/640] {} }

.about-2-section .block-content {
  @apply border-t border-secondary-2 {} }

@screen xl {
  .about-2-section {
    @apply sticky top-0 left-0 -z-1 {} } }

.about-3-list {
  @apply grid grid-cols-1 gap-10 md:grid-cols-2 {} }

.about-3-item {
  @apply aspect-[320/416] relative {} }
  .about-3-item .caption {
    @apply absolute left-1/2 -translate-x-1/2 w-[calc(100%-(40/1920*100rem))] z-1 rem:top-[100px] flex flex-col gap-3 rem:py-[10px] border-t {} }
  .about-3-item .title {
    @apply text-32 font-light leading-tight -tracking-[1.28px] {} }
  .about-3-item.is-white .caption {
    @apply border-white/40 {} }
  .about-3-item.is-white .title, .about-3-item.is-white .btn-lined {
    @apply text-white border-current {} }
  .about-3-item.is-primary-2 .caption {
    @apply border-primary-2/60 {} }
  .about-3-item.is-primary-2 .title, .about-3-item.is-primary-2 .btn-lined {
    @apply text-primary-2 border-current {} }

.about-3-section {
  @apply xl:pt-23 xl:rem:pb-[132px] {} }
  .about-3-section .block-content {
    @apply rem:max-w-[520px] {} }
  .about-3-section .swiper-slide {
    @apply rem:w-[320px] {} }

@screen xl {
  .about-3-section {
    @apply sticky top-0 left-0 -z-1 {} } }

.about-4-item {
  @apply flex flex-col {} }
  .about-4-item .image {
    @apply rem:h-[262px] {} }
  .about-4-item .caption {
    background: linear-gradient(90deg, rgba(0, 174, 239, 0.05) 0%, #0090E1 50%, rgba(0, 174, 239, 0.05) 100%);
    @apply flex flex-col items-center justify-center gap-1 px-6 py-2 text-center rem:min-h-[80px] flex-1 {} }
  .about-4-item .name {
    @apply body-18 font-bold text-white {} }
  .about-4-item .sub-name {
    @apply body-16 font-normal text-white {} }

.about-4-list {
  @apply grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 {} }
  .about-4-list + .about-4-list {
    @apply rem:mt-[22px] {} }
  .about-4-list.is-bot {
    @apply gap-6 lg:grid-cols-4 {} }
    .about-4-list.is-bot .about-4-item .caption {
      @apply rem:min-h-[72px] py-2 {} }

.about-4-section .tabslet-content {
  @apply rem:mt-[34px] xl:rem:min-h-[738px] {}  @apply xl:hidden !important {} }

.about-5-item {
  @apply flex gap-4 bg-white rounded-2 p-4 body-18 font-normal text-primary-2 tsn xl:px-6 {}  @apply hover:bg-primary-2/10 {} }
  .about-5-item .number {
    @apply font-bold {} }

.about-5-list {
  @apply space-y-1 {} }

.about-5-section .block-content {
  @apply pb-10 border-t border-neutral-200 {} }

.about-5-section .main-tabslet {
  @apply bg-neutral-50 p-5 rounded-2 xl:rounded-4 xl:p-10 {} }

.about-5-section .about-5-list {
  @apply mt-5 xl:mt-10 {} }

.about-6-table {
  @apply bg-white rounded-2 p-4 xl:pt-6 xl:px-10 xl:pb-10 {} }
  .about-6-table table {
    @apply w-full {} }
  .about-6-table th {
    @apply sub-header-24 font-bold text-primary-2 text-left py-3 pr-3 border-0 border-b border-primary-3 first:text-center xl:pr-5 xl:py-4 {} }
  .about-6-table td {
    @apply body-18 font-normal text-primary-2 py-3 pr-3 border-0 border-b border-neutral-200 first:text-center xl:pr-5 {} }
    .about-6-table td:nth-child(1) {
      @apply text-neutral-500 {} }
    .about-6-table td:nth-child(2) {
      @apply font-bold {} }
  @media only screen and (max-width: 1023.98px) {
    .about-6-table {
      @apply overflow-x-auto pb-5 {} }
      .about-6-table table {
        @apply min-w-[1000px] {} } }

.about-6-section .about-6-table {
  @apply mt-10 {} }

.site-quick-link-section {
  @apply relative z-1 rem:py-[105px] {} }
  .site-quick-link-section::before {
    content: '';
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    @apply absolute inset-0 -z-1 {} }

[data-fancybox] {
  @apply cursor-pointer {} }

.image-video {
  @apply relative {} }
  .image-video::before {
    @apply font-awesome-sharp content-['\f04b'] absolute-center size-16 flex-center bg-primary-2 rounded-full text-2xl font-thin text-white pointer-events-none tsn z-1 {} }
  .image-video:hover {
    @apply before:scale-110 {} }

.box-easy-1 {
  @apply spacing-mobile bg-gradient-4 flex flex-col gap-5 xl:p-10 {} }
  .box-easy-1 .image {
    @apply aspect-[660/330] {} }

.easy-1-section {
  background-position: bottom right !important;
  @apply xl:rem:py-[90px] {} }
  .easy-1-section .block-content {
    @apply rem:max-w-[620px] xl:ml-auto xl:mr-0 {} }
  .easy-1-section .site-desc h2 {
    @apply sub-header-24 font-semibold text-primary-2 mb-5 {} }
    .easy-1-section .site-desc h2 a {
      @apply underline {} }

.easy-2-item {
  @apply flex flex-col gap-4 text-center border-4 border-white shadow-drop-shadow-light bg-primary-3 rounded-4 p-5 rem:min-h-[200px] xl:rem:w-[200px] {} }
  .easy-2-item .icon {
    @apply size-20 mx-auto {} }
  .easy-2-item .title {
    @apply body-18 font-normal text-white {} }
  .easy-2-item:nth-child(2) {
    @apply bg-primary-6 {} }
  .easy-2-item:nth-child(3) {
    @apply bg-primary-4 {} }
  .easy-2-item:nth-child(4) {
    @apply bg-primary-2 {} }

.easy-2-list {
  @apply grid grid-cols-2 gap-5 md:grid-cols-4 xl:rem:max-w-[860px] {} }

.easy-2-section {
  @apply xl:pb-0 xl:rem:min-h-[720px] {} }
  .easy-2-section .image {
    @apply rounded-4 aspect-[880/640] rem:max-w-[880px] mx-auto xl:rounded-none xl:rounded-t-4 {} }
  .easy-2-section .site-desc {
    @apply rem:max-w-[680px] {} }
  .easy-2-section .container {
    @apply pointer-events-none {} }

@screen xl {
  .easy-2-section .image {
    @apply absolute bottom-0 right-0 -z-1 {} } }

.box-easy-3 {
  @apply spacing-mobile bg-white xl:p-10 {} }
  .box-easy-3 .swiper {
    @apply rem:max-w-[920px] mx-auto {} }
    .box-easy-3 .swiper .image {
      @apply aspect-[920/640] {} }

@screen lg {
  .box-easy-3 .swiper-button > * {
    @apply absolute top-1/2 -translate-y-1/2 z-1 {} }
  .box-easy-3 .swiper-button .button-prev {
    @apply left-5 {} }
  .box-easy-3 .swiper-button .button-next {
    @apply right-5 {} } }

@screen xl {
  .box-easy-3 .swiper-button .button-prev {
    @apply rem:left-[72px] {} }
  .box-easy-3 .swiper-button .button-next {
    @apply rem:right-[72px] {} } }

.easy-3-item {
  @apply flex flex-col gap-3 {} }
  .easy-3-item .image {
    @apply aspect-[320/200] rounded-[8px] xl:rounded-4 {} }
  .easy-3-item .title {
    @apply sub-header-20 font-semibold text-primary-2 text-center underline {} }

.easy-3-section .table-shareholder-download td:nth-child(1) {
  @apply w-10 text-neutral-500 text-center pr-0 {} }

.easy-3-section .table-shareholder-download td:nth-child(2) {
  @apply pl-5 {} }
  .easy-3-section .table-shareholder-download td:nth-child(2) a {
    @apply underline {} }

.easy-3-section .four-slider .swiper {
  @apply overflow-visible {} }

.easy-4-section .iframe-scale {
  @apply pt-[calc(700/1400*100%)] {} }

.easy-5-top-item {
  @apply flex flex-col gap-4 p-4 rounded-4 bg-white mx-auto {} }
  .easy-5-top-item .image {
    @apply aspect-[272/160] rounded-2 {} }
  .easy-5-top-item .title {
    @apply sub-header-20 font-bold text-primary-2 text-center underline {} }

.easy-5-top-list {
  @apply grid grid-cols-3 gap-6 {} }

.easy-5-bot-item {
  @apply rem:px-[10px] py-5 flex justify-center gap-3 xl:gap-5 {} }
  .easy-5-bot-item .item {
    @apply text-center {} }
  .easy-5-bot-item img {
    @apply h-16 object-contain {} }
  .easy-5-bot-item figcaption {
    @apply body-14 font-normal text-white text-center mt-2 {} }

@screen lg {
  .easy-5-bot-list {
    @apply flex justify-center gap-10 {} } }

@screen xl {
  .easy-5-bot-list {
    @apply gap-20 {} }
    .easy-5-bot-list > * + * {
      @apply relative {} }
      .easy-5-bot-list > * + *::before {
        content: '';
        @apply absolute top-1/2 -translate-y-1/2 -left-10 w-px h-20 bg-white/40 pointer-events-none {} } }

.easy-5-section {
  @apply overflow-hidden xl:pb-22 {} }
  .easy-5-section .auto-slider .swiper {
    @apply overflow-visible {} }
  .easy-5-section .easy-5-top-list {
    @apply rem:max-w-[960px] mx-auto {} }

.easy-6-item .icon {
  @apply size-30 bg-white rounded-4 flex-center {} }

.easy-6-list {
  @apply flex-center flex-wrap gap-5 xl:gap-10 {} }

.foa-1-item {
  @apply relative z-1 flex flex-col justify-between rem:gap-[14px] px-[16px] py-[12px] rounded-[8px] border border-black/8 tsn overflow-hidden xl:px-6 xl:py-5 xl:rounded-4 {} }
  .foa-1-item::before {
    content: '';
    @apply absolute bottom-0 left-0 w-full h-0 bg-primary-2 tsn -z-1 {} }
  .foa-1-item * {
    @apply tsn {} }
  .foa-1-item .icon {
    @apply size-[48px] xl:size-16 {} }
  .foa-1-item .top {
    @apply flex flex-row gap-[16px] md:flex-row xl:gap-4 {} }
  .foa-1-item .caption {
    @apply flex-1 flex flex-col {} }
  .foa-1-item .number {
    @apply text-[32px] font-bold text-primary-2 leading-tight xl:text-40 {} }
  .foa-1-item .suffix {
    @apply text-[14px] font-normal text-primary-2 leading-[1.29] rem:-mt-1 xl:text-base {} }
  .foa-1-item .sub-number {
    @apply text-[14px] font-normal text-primary-2 leading-[1.29] rem:-mt-1 xl:text-base {} }
  .foa-1-item .title {
    @apply text-[16px] font-normal text-black leading-snug xl:text-lg {} }
  .foa-1-item:hover {
    @apply border-primary-2 before:h-full {} }
    .foa-1-item:hover * {
      @apply text-white {} }

.foa-1-list {
  @apply grid grid-cols-2 gap-5 md:grid-cols-4 xl:gap-10 {} }

.foa-item {
  @apply rounded-4 overflow-hidden flex flex-col md:flex-row {} }
  .foa-item .caption {
    @apply flex-1 flex flex-col justify-between backdrop-blur px-[24px] py-[20px] md:p-8 xl:p-12 {} }
  .foa-item .title {
    @apply text-[24px] font-bold text-white leading-tight xl:text-32 {} }
  .foa-item .image {
    @apply aspect-square md:aspect-[960/400] md:w-2/3 xl:rem:w-[960px] {} }
    .foa-item .image img {
      @apply transition-all duration-1000 ease-in-out {} }
    .foa-item .image:hover img {
      @apply scale-110 {} }
  .foa-item .button {
    @apply mt-[42px] xl:mt-5 {} }

.foa-list {
  @apply space-y-10 {} }

.foa-1-section .swiper-slide {
  @apply w-[320px] {} }

.foa-2-section .image {
  @apply rem:max-w-[616px] mx-auto xl:ml-auto xl:mr-0 {} }

.foa-2-section .foa-1-list {
  @apply grid-cols-2 {} }

.foa-2-section .block-content {
  @apply xl:pt-20 {} }

.site-links {
  @apply flex items-center overflow-x-auto gap-3 xl:justify-center xl:overflow-visible {} }
  .site-links a {
    @apply flex-center gap-3 body-16 font-medium text-neutral-950 border border-neutral-200 bg-neutral-100 rounded-full h-11 px-5 py-2 whitespace-nowrap {}    @apply hover:bg-primary-1 hover:text-white hover:border-primary-1 {} }
  .site-links li {
    @apply w-max {} }
    .site-links li.active a {
      @apply bg-primary-1 text-white border-primary-1 {} }

.foa-detail-2-dealer-1 .block-content {
  @apply rem:max-w-[960px] {} }

.foa-detail-2-dealer-1 .block-image {
  @apply aspect-[1400/700] {} }

.foa-detail-2-dealer-1 .block-iframe .iframe-scale {
  @apply pt-[calc(700/1400*100%)] {} }

.foa-detail-2-dealer-1 .block-foa-dealer-3-image {
  @apply aspect-[1400/700] rounded-4 {} }

.foa-dealer-2-item {
  @apply p-4 bg-secondary-1 rounded-4 xl:p-10 xl:pt-6 {} }
  .foa-dealer-2-item table {
    @apply w-full {} }
  .foa-dealer-2-item tr {
    @apply pb-1 {} }
  .foa-dealer-2-item th {
    @apply sub-header-24 font-bold text-primary-2 text-left py-3 pr-3 border-b border-primary-3 xl:pr-5 xl:rem:py-[15px] {}    @apply last:text-right last:pr-0 {} }
  .foa-dealer-2-item td {
    @apply body-18 font-normal text-primary-2 text-left py-2 pr-3 border-b border-neutral-200 xl:pr-5 xl:py-3 {}    @apply last:text-right last:pr-0 {} }

.foa-dealer-2-list {
  @apply grid grid-cols-1 gap-10 lg:grid-cols-2 {} }

.foa-dt-2-2-section .swiper-slide {
  @apply w-[320px] {} }

.foa-dealer-4-item {
  @apply flex flex-col bg-white rounded-4 overflow-hidden border border-[#eee] cursor-pointer {} }
  .foa-dealer-4-item .image {
    @apply aspect-[440/200] {} }
  .foa-dealer-4-item .caption {
    @apply flex-1 bg-neutral-50 p-5 tsn xl:px-6 {} }
  .foa-dealer-4-item .news-date {
    @apply mb-2 {} }
  .foa-dealer-4-item .title {
    @apply my-2 sub-header-20 font-bold text-primary-2 line-clamp-2 {} }
  .foa-dealer-4-item:hover .caption {
    @apply bg-neutral-100 {} }

.foa-dealer-4-list {
  @apply grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 {} }

.foa-dt-1-1-item {
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.02);
  @apply flex flex-col gap-10 border-2 border-white p-5 rounded-4 bg-white bg-gradient-1 md:flex-row xl:gap-12 xl:p-10 xl:px-12 {} }
  .foa-dt-1-1-item .title {
    @apply sub-header-24 font-semibold text-primary-2 md:flex-1 {} }
  .foa-dt-1-1-item .image {
    @apply aspect-[320/240] md:rem:w-[320px] {} }
  .foa-dt-1-1-item.is-chart-1 {
    @apply bg-gradient-1 xl:p-10 {} }
    .foa-dt-1-1-item.is-chart-1 .image {
      @apply aspect-[600/320] w-full {} }
  .foa-dt-1-1-item.is-chart-2 {
    @apply bg-gradient-4 border-none xl:p-10 {} }
    .foa-dt-1-1-item.is-chart-2 .image {
      @apply aspect-[600/240] w-full {} }

.foa-dt-1-1-section {
  background: linear-gradient(0deg, #C5DEE9 0%, #EDF2F6 100%); }
  .foa-dt-1-1-section .block-content {
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.02);
    @apply p-5 rounded-4 bg-white h-full xl:p-12 {} }
  .foa-dt-1-1-section .site-title {
    @apply pb-6 border-b border-primary-5 mb-6 {} }
  .foa-dt-1-1-section .site-desc > * {
    @apply mb-5 first:mb-6 last:mb-0 {} }
  .foa-dt-1-1-section .site-desc strong {
    @apply sub-header-24 font-semibold text-primary-2 {} }
  .foa-dt-1-1-section .foa-1-list {
    @apply grid-cols-1 sm:grid-cols-2 gap-10 {} }
  .foa-dt-1-1-section .foa-1-item {
    @apply bg-white border-white xl:gap-8 {} }
    .foa-dt-1-1-section .foa-1-item .top {
      @apply rem:pb-[22px] border-b border-black/8 flex-row items-center {} }
  .foa-dt-1-1-section .foa-dt-1-1-list {
    @apply grid grid-cols-1 gap-10 lg:grid-cols-2 {} }

.foa-dt-1-2-section {
  background-position: bottom right !important;
  @apply rem:min-h-[468px] {} }
  .foa-dt-1-2-section .block-content {
    @apply rem:max-w-[1040px] {} }
  .foa-dt-1-2-section .site-title {
    @apply pb-6 border-b border-primary-5 mb-6 {} }
  .foa-dt-1-2-section .site-desc > * {
    @apply mb-5 first:mb-6 last:mb-0 {} }
  .foa-dt-1-2-section .site-desc strong {
    @apply sub-header-24 font-semibold text-primary-2 {} }

.foa-dt-2-1-item {
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.02); }
  .foa-dt-2-1-item .image {
    @apply aspect-[680/480] {} }

.foa-dt-2-1-section {
  background: linear-gradient(0deg, #C5DEE9 0%, #EDF2F6 100%); }
  .foa-dt-2-1-section .block-content {
    @apply p-5 rounded-4 bg-white h-full xl:p-10 xl:rem:pb-[50px] {} }
    .foa-dt-2-1-section .block-content .site-title {
      @apply sub-header-24 font-semibold {} }
  .foa-dt-2-1-section .foa-1-list {
    @apply grid-cols-1 sm:grid-cols-2 gap-10 h-full {} }
  .foa-dt-2-1-section .foa-1-item {
    @apply bg-white border-white h-full xl:gap-8 {} }
    .foa-dt-2-1-section .foa-1-item .top {
      @apply rem:pb-[22px] border-b border-black/8 flex-row {} }
  .foa-dt-2-1-section .block-image {
    @apply p-5 rounded-4 bg-white h-full xl:p-10 {} }
    .foa-dt-2-1-section .block-image .image {
      @apply aspect-[1320/428] {} }
  .foa-dt-2-1-section .slider-top {
    @apply flex-center gap-3 {} }
    .foa-dt-2-1-section .slider-top .image {
      @apply rem:size-[152px] {} }
    .foa-dt-2-1-section .slider-top .button-prev, .foa-dt-2-1-section .slider-top .button-next {
      @apply rem:w-[10px] rem:h-[20px] {} }
  .foa-dt-2-1-section .block-slider {
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.02);
    @apply p-5 rounded-4 bg-gradient-4 overflow-hidden xl:p-12 xl:rem:min-h-[400px] {} }
    .foa-dt-2-1-section .block-slider .title {
      @apply sub-header-24 font-semibold text-white text-center mt-10 mx-auto rem:max-w-[1144px] {} }
  .foa-dt-2-1-section .foa-dt-2-1-list {
    @apply grid grid-cols-1 gap-10 lg:grid-cols-2 {} }

.foa-dt-2-2-item {
  @apply flex flex-col {} }
  .foa-dt-2-2-item .image {
    @apply aspect-[680/400] rounded-4 {} }
  .foa-dt-2-2-item .button {
    @apply absolute left-0 flex-center z-1 top-[20px] w-full pointer-events-none xl:top-10 {} }
    .foa-dt-2-2-item .button a {
      @apply pointer-events-auto {} }
  .foa-dt-2-2-item .caption {
    @apply py-5 text-center border-b border-neutral-200 xl:rem:py-[25px] {} }
  .foa-dt-2-2-item .title {
    @apply sub-header-24 font-semibold text-black text-center hover:text-primary-1 {} }
  .foa-dt-2-2-item .desc {
    @apply mt-[12px] text-[16px] leading-snug font-normal text-black text-center xl:mt-5 xl:text-xl {} }
  .foa-dt-2-2-item.is-dealer {
    @apply bg-white rounded-4 overflow-hidden md:col-span-2 md:flex-row xl:bg-white {} }
    .foa-dt-2-2-item.is-dealer .image {
      @apply aspect-[358/252] md:rem:w-[680px] xl:aspect-[680/400] {} }
    .foa-dt-2-2-item.is-dealer .caption {
      @apply border-none flex-center flex-col px-[16px] py-[24px] md:flex-1 md:px-10 xl:px-20 {} }
    .foa-dt-2-2-item.is-dealer .title {
      @apply xl:text-32 xl:font-bold xl:leading-[calc(40/32)] {} }

.foa-dt-2-2-list {
  @apply grid grid-cols-1 gap-10 md:grid-cols-2 {} }

.foa-dt-3-1-section {
  @apply bg-gradient-4 {} }
  .foa-dt-3-1-section .block-content {
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.02);
    @apply p-5 rounded-4 bg-white h-full xl:p-12 {} }
  .foa-dt-3-1-section .site-title {
    @apply pb-6 border-b border-primary-5 mb-6 {} }
  .foa-dt-3-1-section .site-desc > * {
    @apply mb-5 first:mb-6 last:mb-0 {} }
  .foa-dt-3-1-section .site-desc strong {
    @apply sub-header-24 font-semibold text-primary-2 {} }
  .foa-dt-3-1-section .foa-dt-1-1-item {
    @apply xl:rem:p-[38px] {} }
    .foa-dt-3-1-section .foa-dt-1-1-item .image {
      @apply aspect-[600/280] w-full {} }
  .foa-dt-3-1-section .foa-1-list {
    @apply grid-cols-1 sm:grid-cols-2 gap-10 {} }
  .foa-dt-3-1-section .foa-1-item {
    @apply bg-primary-3 border-primary-3 xl:gap-14 {} }
    .foa-dt-3-1-section .foa-1-item .top {
      @apply rem:pb-[22px] border-b border-white/20 flex-row items-center {} }
    .foa-dt-3-1-section .foa-1-item * {
      @apply text-white {} }

.footer-info {
  @apply text-center text-[16px] font-normal leading-snug xl:text-base {} }
  .footer-info h4 {
    @apply text-[24px] font-bold leading-tight mb-[24px] xl:text-32 xl:mb-6 {} }
  .footer-info ul {
    @apply space-y-[4px] mt-[16px] xl:mt-2 {} }
  .footer-info a {
    @apply hover:text-primary-3 {} }

@screen xl {
  .footer-info ul {
    @apply flex-center gap-4 space-y-0 {} }
    .footer-info ul li {
      @apply flex items-center {} }
      .footer-info ul li + li::before {
        content: '';
        @apply inline-block rem:size-[3px] bg-white mr-4 {} } }

.footer-links {
  @apply flex flex-wrap items-center mt-[12px] {} }
  .footer-links a {
    @apply flex items-center gap-[8px] text-[16px] font-normal leading-snug xl:text-base xl:gap-2 {}    @apply first:hidden last:after:hidden md:first:flex {} }
    .footer-links a span {
      @apply hover-underline {} }
    .footer-links a::after {
      content: '';
      @apply w-px h-[12px] bg-white/40 mx-[20px] xl:mx-5 {} }
    .footer-links a.current-menu-item {
      @apply text-white {} }
      .footer-links a.current-menu-item span {
        @apply hover-underline-active {} }

.footer-bot {
  @apply mt-[20px] text-[14px] font-normal leading-tight py-[8px] xl:py-5 xl:rem:mt-[140px] xl:text-base {} }

@screen md {
  .footer-bot {
    @apply flex items-center justify-between {} } }

footer {
  @apply relative z-1 overflow-hidden pt-[32px] pb-[64px] bg-primary-2 text-white xl:pt-20 xl:pb-5 {} }
  footer .footer-bg {
    @apply absolute bottom-0 -z-1 left-0 w-full rem:h-[200px] aspect-[1920/200] pointer-events-none {}    @apply hidden xl:block {} }

header {
  @apply fixed top-0 left-0 w-full z-40 bg-white backdrop-blur h-[100px] xl:h-auto xl:bg-white/80 xl:backdrop-blur xl:z-999 {} }
  header .header-wrap {
    @apply flex items-center justify-between gap-4 h-[60px] px-[10px] shadow-drop-shadow-light md:px-4 xl:h-25 xl:px-10 {} }
  header .header-left {
    @apply flex items-center gap-12 {} }
  header .header-right {
    @apply flex items-center gap-[8px] xl:gap-4 {} }
  header .logo {
    @apply w-[116px] h-[50px] xl:rem:w-[172px] xl:h-15 {} }
  header .navbar-nav {
    @apply hidden xl:block {} }
  header .main-menu {
    @apply flex items-center gap-8 {} }
    header .main-menu li:hover > a {
      @apply text-primary-1 hover-underline-active !important {} }
    header .main-menu li:hover > .sub-menu {
      @apply opacity-100 pointer-events-auto translate-y-0 {} }
    header .main-menu li[class*="current-menu-"] > a {
      @apply text-primary-1 hover-underline-active !important {} }
    header .main-menu li.menu-item-has-children {
      @apply relative {} }
      header .main-menu li.menu-item-has-children > a {
        @apply relative {} }
        header .main-menu li.menu-item-has-children > a::after {
          @apply font-awesome content-['\f107'] text-base font-black text-primary-1 rem:ml-[10px] {} }
    header .main-menu > li {
      @apply h-25 flex-center first:hidden last:hidden {} }
      header .main-menu > li > a {
        @apply flex-center h-full sub-header-20 font-semibold text-black hover-underline {} }
      header .main-menu > li > .sub-menu {
        @apply absolute top-full left-0 rem:w-[320px] bg-neutral-50 border-t-2 border-primary-1 p-10 opacity-0 pointer-events-none tsn translate-y-5 rem:max-h-[calc(100vh-(100/1920*100rem))] overflow-y-auto {} }
  header .toggle-submenu {
    @apply hidden {} }
  header .sub-menu {
    @apply space-y-6 {} }
    header .sub-menu > li > a {
      @apply body-16 font-medium text-neutral-950 {} }
    header .sub-menu > li.menu-item-has-children a {
      @apply after:hidden {} }
    header .sub-menu .toggle-submenu {
      @apply hidden {} }
    header .sub-menu .sub-menu {
      @apply list-disc pl-5 space-y-3 mt-3 {} }
      header .sub-menu .sub-menu > li > a {
        @apply body-14 font-medium text-neutral-950 {} }
  header .button-language {
    @apply order-2 xl:order-1 {} }
  header .button-search {
    @apply order-1 size-[48px] flex-center cursor-pointer text-[24px] text-neutral-400 xl:size-10 xl:text-primary-1 xl:order-2 xl:text-base {} }
  header #buttonMenu {
    @apply order-3 {} }

.mobile-wrap {
  @apply fixed top-[60px] w-screen h-[calc(100vh-60px)] bg-primary-3 shadow-drop-shadow-light z-[1001] pt-[14px] pl-[16px] pb-[16px] max-w-[390px] -left-full opacity-0 tsn pointer-events-none xl:hidden {} }
  .mobile-wrap .navbar-nav-list {
    @apply pr-[16px] flex flex-col max-h-[calc(100vh-142px)] overflow-y-auto overflow-x-hidden {} }
  .mobile-wrap .toggle-submenu {
    @apply absolute right-0 z-1 flex justify-end items-center size-[60px] top-0 {} }
    .mobile-wrap .toggle-submenu::before {
      @apply font-awesome content-['\f054'] text-[16px] font-light text-white tsn {} }
    .mobile-wrap .toggle-submenu.active::before {
      @apply rotate-90 {} }
  .mobile-wrap .main-menu li[class*="current-menu-"] > a {
    @apply text-primary-2 font-bold !important {} }
  .mobile-wrap .main-menu > li {
    @apply border-b border-white/20 relative {} }
    .mobile-wrap .main-menu > li > a {
      @apply inline-block py-[16px] text-[18px] font-normal text-white {} }
    .mobile-wrap .main-menu > li > .sub-menu {
      @apply pl-5 hidden {} }
  .mobile-wrap .sub-menu {
    @apply space-y-6 pb-5 {} }
    .mobile-wrap .sub-menu > li > a {
      @apply text-[16px] font-medium text-white {} }
    .mobile-wrap .sub-menu .toggle-submenu {
      @apply hidden {} }
    .mobile-wrap .sub-menu .sub-menu {
      @apply list-disc pl-5 space-y-3 mt-3 {} }
      .mobile-wrap .sub-menu .sub-menu > li {
        @apply marker:text-white {} }
        .mobile-wrap .sub-menu .sub-menu > li > a {
          @apply text-[14px] font-medium text-white {} }
  .mobile-wrap .mobile-language {
    @apply h-[24px] flex items-center gap-[12px] text-[16px] text-white mt-[28px] {} }
    .mobile-wrap .mobile-language .wpml-ls {
      @apply border-l border-neutral-300 pl-3 h-[18px] {} }
      .mobile-wrap .mobile-language .wpml-ls.wpml-ls-legacy-list-horizontal ul {
        @apply relative {} }
        .mobile-wrap .mobile-language .wpml-ls.wpml-ls-legacy-list-horizontal ul li {
          @apply flex {} }
          .mobile-wrap .mobile-language .wpml-ls.wpml-ls-legacy-list-horizontal ul li a {
            @apply flex h-[18px] w-auto bg-none bg-transparent rounded-none {} }
          .mobile-wrap .mobile-language .wpml-ls.wpml-ls-legacy-list-horizontal ul li.wpml-ls-current-language a {
            @apply flex gap-[4px] pointer-events-none {} }
            .mobile-wrap .mobile-language .wpml-ls.wpml-ls-legacy-list-horizontal ul li.wpml-ls-current-language a::after {
              @apply font-awesome content-['\f078'] text-[12px] font-light text-current {} }
          .mobile-wrap .mobile-language .wpml-ls.wpml-ls-legacy-list-horizontal ul li:not(.wpml-ls-current-language) {
            @apply absolute top-full left-0 opacity-0 {} }
        .mobile-wrap .mobile-language .wpml-ls.wpml-ls-legacy-list-horizontal ul:hover li:not(.wpml-ls-current-language), .mobile-wrap .mobile-language .wpml-ls.wpml-ls-legacy-list-horizontal ul:focus li:not(.wpml-ls-current-language) {
          @apply opacity-100 {} }
  .mobile-wrap.active {
    @apply left-0 opacity-100 pointer-events-auto {} }

.search-wrap {
  @apply fixed top-32 left-0 right-0 w-full z-100 hidden px-10 xl:rem:top-[160px] {} }

.wpml-ls.wpml-ls-legacy-dropdown-click {
  @apply w-full {} }
  .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li {
    @apply flex-center {} }
    .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > a {
      @apply flex-center text-base font-normal text-neutral-950 bg-none bg-transparent p-0 border-0 {} }
      .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > a::after {
        @apply font-awesome content-['\f078'] text-sm font-light text-current ml-1 border-none flex static {} }
    .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > .wpml-ls-sub-menu {
      @apply border-none absolute top-full {} }

.wpml-ls.wpml-ls-legacy-list-horizontal ul {
  @apply flex items-center gap-2 {} }
  .wpml-ls.wpml-ls-legacy-list-horizontal ul li a {
    @apply size-[48px] rounded-[8px] flex-center tsn bg-secondary-1 xl:bg-none xl:bg-transparent xl:border-b xl:border-transparent xl:rounded-none xl:size-6 xl:opacity-50 {} }
  .wpml-ls.wpml-ls-legacy-list-horizontal ul li:hover a {
    @apply xl:opacity-100 {} }
  .wpml-ls.wpml-ls-legacy-list-horizontal ul li.wpml-ls-current-language {
    @apply hidden xl:block {} }
    .wpml-ls.wpml-ls-legacy-list-horizontal ul li.wpml-ls-current-language a {
      @apply xl:border-primary-1 xl:opacity-100 {} }

.site-menu-mobile {
  @apply h-[40px] flex items-center gap-6 overflow-hidden max-w-full px-4 py-0 bg-neutral-50 overflow-x-auto xl:hidden {} }
  .site-menu-mobile::-webkit-scrollbar {
    @apply w-0 h-0 {} }
  .site-menu-mobile li {
    @apply w-max {} }
    .site-menu-mobile li a {
      @apply whitespace-nowrap text-[16px] font-normal text-black h-[40px] flex-center {} }
    .site-menu-mobile li[class*="current-menu-"] a {
      @apply text-primary-1 {} }

.home-page main {
  @apply xl:pt-0 {} }

.home-news-bot {
  @apply mt-[20px] pt-[20px] border-t border-neutral-200 xl:pt-6 xl:mt-6 {} }

.home-news-top {
  @apply mt-[20px] xl:mt-9 {} }

.swiper-news-item {
  @apply w-[320px] xl:w-[320px] {} }

.block-news-category {
  @apply flex flex-col gap-[20px] xl:gap-5 {} }
  .block-news-category .box-head {
    @apply text-[20px] font-semibold text-primary-4 leading-1.3 w-full justify-between py-[4px] border-b border-primary-4 xl:py-1 xl:text-xl {}    @apply hover:text-primary-4 {} }
    .block-news-category .box-head .icon {
      @apply justify-start size-[40px] flex-center text-[18px] text-primary-4 xl:text-lg xl:size-10 {} }
      .block-news-category .box-head .icon > * {
        @apply h-full w-full min-w-full {} }
  .block-news-category .box-image {
    @apply aspect-[320/160] rounded-[8px] block xl:rounded-4 {} }
    .block-news-category .box-image img {
      @apply tsn {} }
    .block-news-category .box-image:hover img {
      @apply scale-110 {} }

.home-news-list {
  @apply grid grid-cols-2 gap-10 {} }

.group-news-category {
  @apply grid grid-cols-2 gap-10 {} }

.group-news-category-mobile {
  @apply mt-[52px] {} }
  .group-news-category-mobile .block-news-category {
    @apply relative {} }
    .group-news-category-mobile .block-news-category .box-head {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.3) 100%);
      @apply absolute top-1/2 -translate-y-1/2 left-0 w-full z-1 min-h-[80px] px-5 justify-center gap-[4px] text-[18px] text-primary-1 font-bold border-none {} }
      .group-news-category-mobile .block-news-category .box-head .icon {
        @apply text-primary-1 {} }

.home-news {
  @apply overflow-hidden pb-[40px] xl:pt-18 xl:rem:pb-[66px] lg:overflow-visible {} }
  .home-news .block-content {
    @apply sticky top-25 xl:rem:max-w-[600px] {} }
  .home-news .auto-slider .swiper {
    @apply overflow-visible lg:overflow-hidden {} }

@screen xl {
  .home-news .block-news-category:first-child {
    @apply mt-11 {} } }

@screen xl {
  .home-news .group-news-category .block-news-category:first-child {
    @apply mt-0 {} } }

.home-gas-price {
  @apply pb-[30px] xl:bg-primary-2 xl:pb-0 xl:rem:min-h-[174px] xl:flex xl:items-center {} }

.home-3-item {
  @apply flex flex-col gap-[16px] xl:gap-2 {} }
  .home-3-item .image {
    @apply aspect-[320/200] rounded-[8px] xl:rounded-4 {} }
    .home-3-item .image img {
      @apply tsn {} }
  .home-3-item .caption {
    @apply flex-1 pb-[18px] border-b border-primary-5 xl:pb-0 {} }
  .home-3-item .title {
    @apply text-[18px] font-normal text-black leading-[1.3] hover:text-primary-2 xl:text-lg xl:min-h-15 xl:flex xl:items-center xl:py-4 {} }
  .home-3-item:hover .image img {
    @apply scale-110 {} }
  .home-3-item:hover .title {
    @apply text-primary-2 {} }

@screen xl {
  .home-3-item:nth-child(1) .title {
    @apply text-2xl font-bold {} } }

.home-3-list {
  @apply grid grid-cols-3 gap-x-10 gap-y-8 {} }
  .home-3-list .home-3-item .image {
    @apply aspect-[440/280] {} }
  .home-3-list .home-3-item:nth-child(1) {
    @apply row-span-2 col-span-2 col-start-2 row-start-1 {} }
    .home-3-list .home-3-item:nth-child(1) .image {
      @apply aspect-[920/658] {} }
  .home-3-list .home-3-item:nth-child(2) {
    @apply col-start-1 row-start-1 {} }
  .home-3-list .home-3-item:nth-child(3) {
    @apply row-start-2 {} }

.home-3-section {
  @apply pb-[38px] overflow-hidden xl:pb-20 {} }
  .home-3-section .swiper-relative {
    @apply mt-[16px] {} }
  .home-3-section .swiper {
    @apply overflow-visible {} }

.home-4-item {
  @apply flex flex-col items-center gap-[12px] bg-secondary-1 rounded-[8px] p-[20px] text-center min-h-[140px] xl:p-6 xl:rounded-4 xl:gap-3 xl:rem:min-h-[148px] xl:justify-center {} }
  .home-4-item .sub-title {
    @apply text-[16px] font-normal text-neutral-500 leading-snug {} }
  .home-4-item .title {
    @apply text-[24px] font-bold text-primary-2 leading-snug xl:text-32 {} }
  .home-4-item:first-child .title {
    @apply text-[16px] xl:text-lg {} }
  .home-4-item:last-child .sub-title {
    @apply max-w-[80px] md:max-w-full {} }

.home-4-list {
  @apply grid grid-cols-2 gap-[12px] lg:grid-cols-4 xl:gap-10 xl:grid-cols-2 {} }

.home-4-left .iframe-scale {
  @apply pt-[calc(500/358*100%)] md:pt-[calc(500/680*100%)] lg:pt-[calc(350/680*100%)] xl:pt-[calc(800/680*100%)] {} }
  @media only screen and (min-width: 1366px) {
    .home-4-left .iframe-scale {
      @apply pt-[calc(690/680*100%)] {} } }
  @media only screen and (min-width: 1600px) {
    .home-4-left .iframe-scale {
      @apply pt-[calc(590/680*100%)] {} } }
  @media only screen and (min-width: 1900px) {
    .home-4-left .iframe-scale {
      @apply pt-[calc(500/680*100%)] {} } }

.home-4-section .site-title-large {
  @apply mb-[16px] xl:mb-10 {} }

.home-5-list {
  @apply grid grid-cols-4 gap-10 {} }
  .home-5-list > *:nth-child(1) {
    @apply col-span-2 row-span-3 {} }
  .home-5-list > *:nth-child(2) {
    @apply col-start-1 row-start-4 {} }
  .home-5-list > *:nth-child(3) {
    @apply col-start-2 row-start-4 {} }
  .home-5-list > *:nth-child(4) {
    @apply col-start-3 row-start-1 {} }
  .home-5-list > *:nth-child(5) {
    @apply col-start-4 row-start-1 {} }
  .home-5-list > *:nth-child(6) {
    @apply col-start-3 row-start-2 {} }
  .home-5-list > *:nth-child(7) {
    @apply col-start-4 row-start-2 {} }
  .home-5-list > *:nth-child(8) {
    @apply col-start-3 row-start-3 {} }
  .home-5-list > *:nth-child(9) {
    @apply col-start-4 row-start-3 {} }
  .home-5-list > *:nth-child(10) {
    @apply row-start-4 {} }
  .home-5-list > *:nth-child(11) {
    @apply row-start-4 {} }

.home-5-mb-list {
  @apply -mx-[16px] px-[16px] mt-[16px] overflow-x-auto overflow-y-hidden grid grid-flow-col grid-rows-2 gap-[16px] {} }
  .home-5-mb-list > * {
    @apply w-[240px] {} }

.home-5-section {
  @apply overflow-hidden {} }
  .home-5-section .image {
    @apply aspect-[358/280] rounded-[8px] mt-[16px] lg:mt-0 xl:rounded-4 xl:aspect-[680/584] {} }

.home-6-section {
  @apply bg-neutral-50 overflow-hidden lg:bg-white {} }
  .home-6-section .site-desc {
    @apply text-[16px] text-neutral-950 mt-[16px] xl:hidden {} }
  .home-6-section .swiper {
    @apply overflow-visible {} }

.home-7-section {
  @apply md:rem:pt-[34px] md:pb-5 {} }
  .home-7-section .block-content {
    @apply px-[16px] py-[32px] bg-gradient-4 rounded-[8px] xl:rounded-4 xl:p-10 xl:py-12 {} }
  .home-7-section .main-tabslet {
    @apply mt-[16px] xl:mt-4 {} }
  .home-7-section .swiper-relative {
    @apply mt-[20px] {} }
  .home-7-section .swiper-slide {
    @apply w-[296px] {} }
  .home-7-section .tabslet-tabs {
    @apply justify-start {} }
  .home-7-section .media-list {
    @apply grid grid-cols-1 gap-10 lg:grid-cols-2 {} }

@screen md {
  .home-7-section .media-list .media-item:nth-child(n+2) {
    @apply flex-row {} }
    .home-7-section .media-list .media-item:nth-child(n+2) .image {
      @apply rem:w-[360px] aspect-[360/240] {} }
    .home-7-section .media-list .media-item:nth-child(n+2) .caption {
      @apply py-5 {} }
    .home-7-section .media-list .media-item:nth-child(n+2) .title {
      @apply sub-header-20 {} } }

@screen lg {
  .home-7-section .media-list .media-item:nth-child(1) {
    @apply row-span-2 {} } }

.home-8-section .foa-dt-2-2-list {
  @apply xl:gap-y-20 {} }

.home-8-section .foa-dt-2-2-item:last-child .image {
  @apply rounded-none xl:aspect-[680/480] {} }

.home-8-section .foa-dt-2-2-item:last-child .caption {
  @apply bg-secondary-1 {} }

.home-9-section {
  @apply relative {} }
  .home-9-section .image {
    @apply aspect-[390/252] md:aspect-[1920/720] xl:aspect-[1920/720] {} }
  .home-9-section .block-content {
    @apply px-[16px] py-[32px] bg-gradient-4 backdrop-blur-[6px] xl:px-9 xl:py-10 {} }
  .home-9-section .site-desc {
    @apply mt-[16px] text-[16px] xl:text-xl xl:mt-5 {} }
  .home-9-section .button {
    @apply mt-[16px] xl:mt-6 {} }

@screen lg {
  .home-9-section .block-content {
    background: linear-gradient(180deg, rgba(21, 101, 174, 0.6) 0%, rgba(0, 174, 239, 0.6) 100%);
    @apply absolute top-10 left-1/2 -translate-x-1/2 z-1 rounded-4 rem:max-w-[800px] w-full {} } }

.contact-iframe .iframe-scale {
  @apply pt-[calc(720/1920*100%)] {} }

.box-contact-form {
  @apply spacing-mobile bg-neutral-50 xl:p-12 {}  @apply rounded-none {} }
  .box-contact-form .row {
    @apply rem:-mx-[6px] {} }
    .box-contact-form .row > * {
      @apply rem:px-[6px] {} }
  .box-contact-form .form-group.form-submit {
    @apply flex-center {} }
  .box-contact-form .form-group button {
    @apply text-primary-2 border-current {} }

.box-contact {
  @apply xl:rem:max-w-[660px] xl:py-10 {} }
  .box-contact .site-desc {
    @apply body-16 pt-5 mt-5 border-t border-[#ccc] xl:mt-8 xl:pt-8 {} }
    .box-contact .site-desc > * {
      @apply my-5 first:mt-0 last:mb-0 {} }
    .box-contact .site-desc a {
      @apply hover:text-primary-1 {} }
    .box-contact .site-desc ul {
      @apply list-disc pl-5 space-y-1 {} }

.audio-wrap {
  @apply p-1 rounded-full bg-secondary-1 md:rem:max-w-[680px] {} }

.audio-flex {
  @apply flex items-center justify-between gap-2 xl:rem:gap-[10px] {} }

.audio-center {
  @apply flex-1 flex items-center gap-2 {} }

.audio-right {
  @apply relative {} }

.audio-toggle-play {
  @apply size-8 bg-primary-2 flex-center text-white text-base rounded-full {} }
  .audio-toggle-play::before {
    @apply font-awesome-sharp content-['\f04b'] font-light {} }
  .audio-toggle-play.playing::before {
    @apply content-['\f04c'] font-bold {} }

.audio-timer-duration {
  @apply flex items-center text-sm font-normal text-neutral-950 md:w-25 {} }

.audio-timeline {
  @apply w-full relative cursor-pointer {} }

.audio-empty {
  @apply h-[4px] rounded-full bg-neutral-200 w-full {} }

.audio-current {
  @apply absolute top-0 left-0 w-0 h-full bg-primary-2 rounded-full {} }
  .audio-current .audio-node {
    @apply absolute top-1/2 -translate-y-1/2 -right-[4px] size-[8px] rounded-full bg-primary-2 cursor-pointer {} }

.audio-voice-current {
  @apply flex items-center gap-1 text-base text-neutral-950 whitespace-nowrap w-max cursor-pointer md:rem:w-[140px] {} }
  .audio-voice-current::after {
    @apply font-awesome-sharp content-['\f078'] text-[10px] font-bold {} }

.audio-voice-list {
  @apply hidden absolute top-8 -right-2 p-2 rounded-2 bg-secondary-2 w-max z-1 {} }
  .audio-voice-list li {
    @apply text-sm font-normal text-neutral-950 cursor-pointer py-1 {} }
  .audio-voice-list::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid rgba(0, 0, 0, 0);
    border-right: 8px solid rgba(0, 0, 0, 0);
    border-bottom: 8px solid theme("colors.secondary.2");
    position: absolute;
    top: -7px;
    right: 15px; }

.box-news-detail .site-title-large {
  @apply text-black leading-[1.17] {} }

.box-news-detail .news-detail-flex {
  @apply flex items-center justify-between gap-5 {} }

.box-news-detail .social-list ul {
  @apply flex items-center gap-3 xl:gap-6 {} }

.box-news-detail .social-list a {
  @apply size-auto rounded-none bg-transparent bg-none body-16 font-normal text-primary-2 flex items-center gap-2 {} }

.box-news-detail .full-content {
  @apply body-18 font-normal text-black leading-[1.33] {} }
  .box-news-detail .full-content p {
    @apply rem:max-w-[920px] mx-auto {} }

@media only screen and (max-width: 575.98px) {
  .box-news-detail .site-title-large {
    @apply text-32 {} } }

.news-other-item .image {
  @apply rounded-t-[8px] overflow-hidden xl:rounded-t-4 {} }

.news-other-item .caption {
  @apply flex flex-col gap-4 spacing-mobile bg-white relative -mt-4 xl:p-6 {} }

.news-other-item .title {
  @apply body-18 font-normal text-black leading-[1.33] hover:text-primary-2 {} }

.news-other-item:hover .title {
  @apply text-primary-2 {} }

.magazine-section .full-content {
  @apply rem:max-w-[1920px] mx-auto {} }

.news-date {
  @apply text-[14px] font-normal text-neutral-500 xl:text-sm {} }

.news-category {
  @apply text-[14px] font-medium text-primary-4 uppercase pr-2 xl:text-sm {} }

.news-flex {
  @apply flex items-center flex-wrap {} }
  .news-flex > * {
    @apply flex items-center last:after:hidden {} }
    .news-flex > *::after {
      content: '';
      @apply block w-[2px] h-[10px] bg-neutral-200 ml-[16px] mr-[8px] xl:rem:h-[10px] xl:rem:w-[2px] xl:ml-4 xl:mr-2 {} }

.news-item {
  @apply flex flex-col gap-4 {} }
  .news-item .image {
    @apply img-cover rounded-[8px] xl:rounded-4 {} }
  .news-item .caption {
    @apply py-2 flex-1 {} }
  .news-item .news-flex {
    @apply mb-3 {} }
  .news-item .title {
    @apply body-18 font-normal text-black tsn {} }
    .news-item .title a {
      @apply line-clamp-3 {} }
  .news-item .desc {
    @apply mt-3 body-16 font-normal text-neutral-500 line-clamp-3 {} }
  .news-item .button {
    @apply mt-5 {} }
  .news-item:hover .title {
    @apply text-primary-2 {} }
  .news-item.is-hor {
    @apply flex gap-[20px] flex-row {} }
    .news-item.is-hor .image {
      @apply w-[150px] h-[100px] aspect-[150/100] rounded-[8px] {} }
    .news-item.is-hor .caption {
      @apply flex-1 py-[4px] xl:py-2 {} }
    .news-item.is-hor .news-flex {
      @apply mb-[8px] xl:mb-3 {} }
    .news-item.is-hor .title {
      @apply body-18 font-normal leading-snug {} }
      .news-item.is-hor .title a {
        @apply line-clamp-4 {} }

@screen xl {
  .news-item.is-hor {
    @apply gap-5 {} }
    .news-item.is-hor .image {
      @apply rem:w-[160px] h-25 aspect-[160/100] rounded-4 {} } }
  .news-item.is-big {
    @apply gap-[20px] xl:gap-5 {} }
    .news-item.is-big .image {
      @apply aspect-[358/228] rounded-[8px] {} }
    .news-item.is-big .caption {
      @apply p-0 {} }
    .news-item.is-big .news-flex {
      @apply mb-[12px] xl:mb-5 {} }
    .news-item.is-big .title {
      @apply sub-header-24 font-semibold {} }

@screen xl {
  .news-item.is-big .image {
    @apply aspect-[600/380] rounded-4 {} } }
  .news-item.is-big-hor {
    @apply rounded-4 overflow-hidden md:flex-row {} }
    .news-item.is-big-hor > * {
      @apply md:w-1/2 {} }
    .news-item.is-big-hor .image {
      @apply md:aspect-[700/400] md:rounded-none {} }
    .news-item.is-big-hor .caption {
      @apply flex flex-col justify-center p-5 md:bg-white md:backdrop-blur md:px-8 xl:p-16 {} }
    .news-item.is-big-hor .news-flex {
      @apply mb-5 {} }
    .news-item.is-big-hor .title {
      @apply sub-header-24 font-bold {} }
      .news-item.is-big-hor .title a {
        @apply line-clamp-2 {} }
    .news-item.is-big-hor .desc {
      @apply mt-5 {} }
    .news-item.is-big-hor .button {
      @apply xl:mt-10 {} }

.news-1-section .news-1-top + .news-1-bot {
  @apply pt-10 mt-7 border-t border-neutral-200 xl:pt-15 xl:pb-5 {} }

.news-1-section .news-list-2-wrap {
  @apply mt-10 {} }

.news-1-section .swiper-relative + .news-list-2-wrap {
  @apply pt-10 mt-7 border-t border-neutral-200 {} }

.news-1-section .form-group {
  @apply flex justify-end items-center gap-3 {} }
  .news-1-section .form-group label {
    @apply body-16 font-normal text-primary-2 {} }

.news-1-section .swiper-slide {
  @apply rem:w-[320px] {} }

@screen xl {
  .news-1-section {
    @apply overflow-hidden {} }
    .news-1-section .news-1-bot .swiper {
      @apply overflow-visible {} } }

.gasoline-price-item {
  @apply relative {} }
  .gasoline-price-item .title {
    @apply body-18 font-medium text-white whitespace-nowrap xl:font-bold {} }
  .gasoline-price-item .number {
    @apply flex items-center gap-2 text-[40px] font-bold text-white leading-1.2 mt-[4px] xl:mt-4 xl:rem:text-[40px] {} }
  .gasoline-price-item .icon {
    @apply text-base {} }
    .gasoline-price-item .icon.sort-up {
      @apply text-[#2AAF53] {} }
    .gasoline-price-item .icon.sort-down {
      @apply text-[#D20000] {} }

.gasoline-prices-list {
  @apply flex overflow-x-auto overflow-y-hidden xl:grid-cols-4 xl:gap-10 {} }
  .gasoline-prices-list::-webkit-scrollbar {
    @apply size-0 hidden {} }
  .gasoline-prices-list .gasoline-price-item {
    @apply w-max px-[12px] border-l border-white/40 {} }

.box-gasoline-prices {
  @apply bg-primary-2 px-[20px] py-[32px] rounded-[8px] flex flex-col gap-[32px] xl:px-8 xl:py-11 xl:flex-row xl:justify-between xl:rounded-4 xl:gap-5 {} }
  .box-gasoline-prices .box-title {
    @apply sub-header-24 font-semibold text-white flex items-center gap-3 py-[5px] xl:gap-5 xl:py-0 {} }
  .box-gasoline-prices .sub-box-title {
    @apply mt-[8px] body-18 font-medium text-white xl:mt-4 xl:font-bold {} }

.announcement-price-item {
  @apply flex flex-col gap-4 rounded-4 border-2 border-neutral-200 bg-white p-4 xl:p-6 {} }
  .announcement-price-item .title {
    @apply sub-header-24 font-bold text-primary-2 {} }
    .announcement-price-item .title a {
      @apply line-clamp-2 {} }
  .announcement-price-item .desc {
    @apply body-16 font-normal text-black line-clamp-4 h-22 {} }
  .announcement-price-item:hover .title {
    @apply text-primary-1 {} }
  .announcement-price-item.no-border {
    @apply border-0 {} }

.announcement-price-list {
  @apply grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 {} }

.media-item {
  @apply flex flex-col rounded-4 overflow-hidden {} }
  .media-item .image {
    @apply aspect-[296/196] xl:aspect-[640/344] {} }
  .media-item .caption {
    @apply p-[20px] bg-white flex-1 xl:p-6 {} }
  .media-item .title {
    @apply py-2 border-b border-black/20 text-[18px] font-bold text-primary-2 leading-[1.33] tsn xl:text-2xl {} }
    .media-item .title a {
      @apply flex items-center gap-2 xl:gap-4 {} }
    .media-item .title .icon {
      @apply flex-center text-lg size-10 rounded-full bg-primary-2 text-white xl:size-12 xl:text-2xl {} }
      .media-item .title .icon ~ span {
        @apply flex-1 line-clamp-2 {} }
  .media-item .desc {
    @apply text-[16px] font-normal text-black mt-5 xl:text-base {} }
  .media-item:hover .title {
    @apply text-primary-1 {} }

@screen md {
  .media-item.is-big-hor {
    @apply flex-row {} }
    .media-item.is-big-hor > * {
      @apply w-1/2 {} }
    .media-item.is-big-hor .caption {
      @apply flex flex-col justify-center {} } }

@screen xl {
  .media-item.is-big-hor .image {
    @apply aspect-[700/440] {} }
  .media-item.is-big-hor .caption {
    @apply p-20 {} } }

.news-3-section .swiper-slide {
  @apply rem:w-[320px] {} }

.news-4-section .tabslet-tabs {
  @apply justify-start {} }

.news-4-section .swiper-slide {
  @apply rem:w-[320px] {} }

.news-4-section .media-list {
  @apply grid grid-cols-1 gap-10 lg:grid-cols-2 {} }

@screen md {
  .news-4-section .media-list .media-item:nth-child(n+2) {
    @apply flex-row {} }
    .news-4-section .media-list .media-item:nth-child(n+2) .image {
      @apply rem:w-[360px] aspect-[360/240] {} }
    .news-4-section .media-list .media-item:nth-child(n+2) .caption {
      @apply py-5 {} }
    .news-4-section .media-list .media-item:nth-child(n+2) .title {
      @apply sub-header-20 {} } }

@screen lg {
  .news-4-section .media-list .media-item:nth-child(1) {
    @apply row-span-2 {} } }

.flipbook-wrapper {
  @apply px-[16px] py-[32px] rounded-[8px] xl:px-10 xl:py-10 xl:rounded-4 bg-secondary-1 {} }
  .flipbook-wrapper .site-title-large {
    @apply bg-gradient-4 bg-primary-3 p-[8px] rounded-[8px] xl:p-4 xl:rounded-2 xl:py-2 {} }

.flipbook {
  @apply overflow-hidden rem:max-w-[1124px] mx-auto {} }

#flipbok_example {
  @apply bg-secondary-1 rem:max-w-[858px] mx-auto overflow-visible !important {} }
  #flipbok_example > .df-ui-btn {
    @apply flex-center p-0 size-10 rounded-full border border-primary-2 text-primary-2 text-lg opacity-100 xl:size-12 xl:text-xl !important {} }
    #flipbok_example > .df-ui-btn::before {
      @apply font-awesome font-normal text-current !important {} }
    #flipbok_example > .df-ui-btn.df-ui-next {
      @apply xl:rem:-right-[128px] !important {} }
      #flipbok_example > .df-ui-btn.df-ui-next::before {
        content: '\f054' !important; }
    #flipbok_example > .df-ui-btn.df-ui-prev {
      @apply xl:rem:-left-[128px] !important {} }
      #flipbok_example > .df-ui-btn.df-ui-prev::before {
        content: '\f053' !important; }
  #flipbok_example > .df-ui-wrapper.df-ui-controls {
    display: none !important; }
  #flipbok_example > .df-outline-container.df-sidemenu {
    display: none !important; }
  @media only screen and (max-width: 575.98px) {
    #flipbok_example > .df-ui-btn.df-ui-next {
      @apply translate-y-0 -translate-x-1/2 top-auto bottom-0 left-[calc(50%+25px)] size-[40px]  !important {} }
    #flipbok_example > .df-ui-btn.df-ui-prev {
      @apply translate-y-0 -translate-x-1/2 top-auto bottom-0 left-[calc(50%-25px)] size-[40px] !important {} } }

.news-list-2-wrap .news-packery-item {
  @apply w-full sm:w-[calc((100%/2)-40px)] lg:w-[calc((100%/3)-40px)] {} }

.news-list-2-wrap .news-packery-list {
  @apply sm:-mr-[40px] {} }

.media-section .form-group {
  @apply flex items-center justify-end gap-3 {} }
  .media-section .form-group label {
    @apply body-16 font-normal text-primary-2 {} }

.media-section .media-list {
  @apply grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 {} }
  .media-section .media-list .media-item .image {
    @apply xl:aspect-[440/280] {} }
  .media-section .media-list .media-item .caption {
    @apply xl:py-5 {} }
  .media-section .media-list .media-item .title {
    @apply xl:text-xl {} }
    .media-section .media-list .media-item .title a {
      @apply xl:gap-3 {} }
    .media-section .media-list .media-item .title .icon {
      @apply size-10 text-base {} }

.podcast-group-left {
  @apply flex items-center flex-1 {} }

.podcast-group-right {
  @apply flex items-center justify-end flex-1 gap-2 {} }

.podcast-timeline {
  @apply h-3 relative rem:my-[10px] {} }

.podcast-empty {
  @apply h-1 absolute top-1/2 -translate-y-1/2 left-0 w-full z-1 bg-secondary-2 cursor-pointer {} }

.podcast-current {
  @apply absolute top-1/2 -translate-y-1/2 left-0 h-1 z-2 bg-primary-2 pointer-events-none {} }
  .podcast-current .podcast-node {
    @apply absolute top-1/2 -translate-y-1/2 rem:-right-[6px] size-3 z-2 bg-primary-2 rounded-full {} }

.podcast-time-count {
  @apply flex items-center gap-2 justify-between body-16 font-normal text-neutral-500 xl:mb-1 {} }

.podcast-control-bar {
  @apply h-18 flex items-center gap-2 justify-between mt-5 xl:rem:mt-[30px] {} }

.podcast-button-flex {
  @apply flex-center gap-2 {} }

.podcast-button {
  @apply flex-center gap-1 {} }
  .podcast-button::before {
    content: '';
    @apply font-awesome-sharp text-base text-primary-2 font-normal leading-none {} }
  .podcast-button.podcast-sound-muted-btn {
    @apply hidden {}    @apply before:content-['\f6a9'] {} }
  .podcast-button.podcast-sound-min-btn {
    @apply before:content-['\f026'] {} }
    .podcast-button.podcast-sound-min-btn.muted {
      @apply before:content-['\f6a9'] {} }
  .podcast-button.podcast-sound-max-btn {
    @apply before:content-['\f028'] {} }
    .podcast-button.podcast-sound-max-btn.muted {
      @apply before:content-['\f6a9'] {} }
  .podcast-button.podcast-play-btn {
    @apply before:content-['\f04b'] before:text-white before:font-thin {} }
  .podcast-button.podcast-pause-btn {
    @apply hidden {}    @apply before:content-['\f04c'] before:text-white before:font-bold {} }
  .podcast-button.podcast-prev-btn {
    @apply before:content-['\f0e2'] after:content-['10'] {} }
  .podcast-button.podcast-next-btn {
    @apply flex-row-reverse before:content-['\f01e'] after:content-['10'] {} }

.podcast-play {
  @apply size-16 flex-center rounded-full overflow-hidden {} }
  .podcast-play > * {
    @apply size-full bg-primary-2 rounded-full {} }
  .podcast-play .podcast-button {
    @apply before:text-2xl before:size-full before:flex-center {} }

.podcast-button-white {
  @apply flex-center h-9 bg-white rounded-full px-3 text-primary-2 body-16 font-normal whitespace-nowrap select-none {} }

.podcast-playback-rate {
  @apply cursor-pointer {} }
  .podcast-playback-rate .podcast-playback-rate-title {
    @apply hidden md:block {} }
  .podcast-playback-rate .podcast-playback-rate {
    @apply font-bold {} }

@media only screen and (max-width: 1023.98px) {
  .podcast-author {
    @apply hidden {} } }

.podcast-volume {
  @apply rem:w-[220px] hidden justify-between gap-1 md:flex {} }

.podcast-volume-bar {
  @apply h-2 flex-1 relative ml-2 {} }
  .podcast-volume-bar::before {
    content: '';
    @apply absolute top-1/2 -translate-y-1/2 -left-2 w-2 rem:h-[2px] bg-primary-2 pointer-events-none z-1 {} }

.podcast-volume-empty {
  @apply absolute top-1/2 -translate-y-1/2 left-0 w-full h-full bg-transparent cursor-pointer {} }
  .podcast-volume-empty::before {
    content: '';
    @apply absolute top-1/2 -translate-y-1/2 -left-2 w-full rem:h-[2px] bg-secondary-2 pointer-events-none -z-1 {} }

.podcast-volume-current {
  @apply absolute top-1/2 -translate-y-1/2 left-0 w-full rem:h-[2px] bg-primary-2 pointer-events-none {} }

.podcast-volume-node {
  @apply absolute top-1/2 -translate-y-1/2 right-0 size-2 z-2 bg-primary-2 rounded-full {} }

.podcast-volume-sound {
  @apply md:hidden {} }

.podcast-full-content {
  @apply p-4 bg-white rounded-2 xl:p-6 xl:rounded-4 {} }

.podcast-timeline-item {
  @apply flex items-center body-16 font-medium text-neutral-950 cursor-pointer py-2 border-t border-secondary-2 first:border-t-0 xl:py-4 {} }
  .podcast-timeline-item .time {
    @apply relative w-14 min-w-14 text-sm text-primary-4 {} }
    .podcast-timeline-item .time::before {
      content: '';
      @apply absolute top-1/2 -translate-y-1/2 right-2 w-px rem:h-[10px] bg-neutral-200 pointer-events-none {} }
    .podcast-timeline-item .time ~ span {
      @apply hover:text-primary-2 hover-underline {} }

.box-podcast {
  @apply spacing-mobile bg-neutral-50 rem:max-w-[1200px] mx-auto xl:rounded-4 xl:p-10 {} }

.podcast-player-wrap {
  @apply md:mb-10 {} }
  .podcast-player-wrap .media-item {
    @apply rounded-none {}    @screen xl {} }
    .podcast-player-wrap .media-item .image {
      @apply aspect-[240/160] rounded-2 {} }
    .podcast-player-wrap .media-item .title {
      @apply flex items-center gap-3 hover:text-primary-2 {} }
    .podcast-player-wrap .media-item .caption {
      @apply bg-none bg-transparent px-0 relative {} }
    .podcast-player-wrap .media-item:hover .title {
      @apply text-primary-2 {} }

@screen md {
  .podcast-player-wrap .media-item {
    @apply flex-row relative {} }
    .podcast-player-wrap .media-item .image {
      @apply rem:w-[240px] rem:h-[160px] {} }
    .podcast-player-wrap .media-item .caption {
      @apply py-3 pl-5 rem:max-w-[500px] static {} } }
  .podcast-player-wrap .social-list {
    @apply absolute top-5 right-0 z-1 md:top-0 {} }
    .podcast-player-wrap .social-list ul {
      @apply justify-end {} }
    .podcast-player-wrap .social-list a {
      @apply bg-primary-3 xl:size-10 hover:bg-primary-2 {} }

.podcast-other-item {
  @apply hidden {} }
  .podcast-other-item .podcast-other-flex {
    @apply flex items-center justify-between gap-2 py-3 border-b border-secondary-2 {} }
  .podcast-other-item .title {
    @apply body-16 font-bold text-primary-2 {} }
    .podcast-other-item .title a {
      @apply flex items-center gap-2 hover-underline {} }
  .podcast-other-item .right {
    @apply flex items-center gap-2 {} }
  .podcast-other-item .icon {
    @apply size-8 flex-center rounded-full bg-primary-2 text-sm text-white {} }

.sitemap {
  @apply py-15 {} }

.sitemap-heading h1 {
  @apply site-title text-center uppercase mx-auto {}  @apply relative w-max max-w-full pb-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px before:bg-gradient {} }

.AspNet-TreeView > ul {
  @apply sm:flex sm:flex-wrap xl:overflow-hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root {
  @apply pb-5 ml-2 first:ml-0 xl:ml-0 xl:pr-5 xl:relative xl:overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root a {
    @apply inline-flex items-center px-7 py-3 text-xl bg-blue-500 text-white rounded-full max-w-full relative z-2 xl:text-sm xl:px-5 xl:hover:bg-primary-2 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > a {
    @apply before:absolute before:left-full before:w-[120%] before:h-px before:bg-neutral-700 before:-z-1 {} }
  .AspNet-TreeView .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
    @apply hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root li {
    @apply pl-3 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li a {
      @apply bg-blue-500/90 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li a {
      @apply bg-blue-500/75 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li li a {
      @apply bg-blue-500/60 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li:last-child {
    @apply before:absolute before:w-px before:h-full before:left-0 before:top-[65%] before:-translate-y-1/2 before:bg-white before:z-1 before:pointer-events-none {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Leaf:last-child {
    @apply before:top-full {} }
  .AspNet-TreeView .AspNet-TreeView-Root:last-child > a {
    @apply before:hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root > ul, .AspNet-TreeView .AspNet-TreeView-Leaf > ul, .AspNet-TreeView .AspNet-TreeView-Parent > ul {
  @apply ml-5 overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li {
    @apply mt-2 relative z-1 {}    @apply after:absolute after:w-px after:h-[200%] after:left-0 after:top-1/2 after:-translate-y-full after:bg-neutral-700 after:pointer-events-none {} }
    .AspNet-TreeView .AspNet-TreeView-Root > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li > a {
      @apply relative {}      @apply before:absolute before:w-3 before:-z-1 before:h-px before:bg-neutral-700 before:top-1/2 before:-translate-y-1/2 before:right-full before:pointer-events-none {} }

.shareholder-1-item {
  @apply flex flex-col rounded-[8px] overflow-hidden lg:flex-row xl:rounded-4 {} }
  .shareholder-1-item .caption {
    @apply spacing-mobile xl:p-16 bg-gradient-4 flex-1 {}    @apply rounded-none {} }
  .shareholder-1-item .image {
    @apply aspect-[880/480] lg:w-2/3 xl:rem:w-[880px] {} }
  .shareholder-1-item .news-date {
    @apply text-base text-white {} }
  .shareholder-1-item .title {
    @apply sub-header-24 font-bold text-white leading-tight {} }
  .shareholder-1-item .desc {
    @apply body-16 font-normal text-white {} }
  .shareholder-1-item .line {
    @apply border-t border-white my-5 xl:my-10 {} }

.shareholder-1-section {
  @apply xl:rem:pt-[92px] {} }
  .shareholder-1-section .block-content {
    @apply rem:max-w-[880px] {} }
  .shareholder-1-section .home-4-list {
    @apply grid grid-cols-2 gap-5 md:grid-cols-4 xl:gap-10 {} }

.table-shareholder-download table {
  @apply w-full {} }

.table-shareholder-download th {
  @apply py-[8px] border-b border-primary-3 xl:py-5 {} }
  .table-shareholder-download th:first-child {
    @apply text-left sub-header-24 font-bold text-primary-2 pr-5 {} }
  .table-shareholder-download th:last-child {
    @apply text-right {} }
    .table-shareholder-download th:last-child label {
      @apply body-16 font-normal text-primary-2 {} }

.table-shareholder-download .form-group {
  @apply flex justify-end items-center gap-3 {} }

.table-shareholder-download td {
  @apply py-[8px] border-b border-neutral-200 xl:py-3 {} }
  .table-shareholder-download td a {
    @apply body-16 font-normal text-primary-2 {} }
  .table-shareholder-download td:first-child {
    @apply pr-5 {} }

.table-shareholder-download .news-date {
  @apply body-16 whitespace-nowrap {} }

.table-shareholder-download .shareholder-flex {
  @apply flex items-center justify-end gap-3 xl:gap-5 {} }

.table-shareholder-download .button a {
  @apply whitespace-nowrap font-medium {} }

@media only screen and (max-width: 767.98px) {
  .table-shareholder-download {
    @apply overflow-x-auto overflow-y-hidden {} }
    .table-shareholder-download table {
      @apply min-w-[600px] {} } }

.box-shareholder-2 {
  @apply spacing-mobile bg-white xl:p-10 {} }
  .box-shareholder-2 .pagination {
    @apply xl:justify-end {} }

.shareholder-3-item {
  @apply flex flex-col gap-2 {} }
  .shareholder-3-item .image {
    @apply aspect-[440/280] rounded-4 {} }
  .shareholder-3-item .caption {
    @apply py-3 flex flex-col gap-3 flex-1 {} }
  .shareholder-3-item .title {
    @apply sub-header-24 font-normal text-black hover:text-primary-1 {} }

.shareholder-3-list {
  @apply grid grid-cols-3 gap-10 {} }

.box-shareholder-4-form {
  @apply spacing-mobile bg-gradient-4 xl:p-12 {} }
  .box-shareholder-4-form .form-group input, .box-shareholder-4-form .form-group textarea {
    @apply border-0 border-b border-white/40 px-0 rem:h-[38px] body-16 font-normal text-white bg-transparent rounded-none {}    @apply placeholder:text-white {}    @apply focus:ring-0 focus:border-white {} }
  .box-shareholder-4-form .form-group textarea {
    @apply h-20 py-2 {} }
  .box-shareholder-4-form .form-submit button {
    @apply ml-auto mr-0 {} }

.accordion-item {
  @apply border-b border-neutral-200 {} }
  .accordion-item .accordion-head {
    @apply flex gap-4 justify-between py-4 border-b border-transparent tsn cursor-pointer {} }
  .accordion-item .accordion-title {
    @apply body-18 font-bold text-primary-2 tsn {} }
  .accordion-item .accordion-content {
    @apply py-5 body-16 font-normal text-black hidden {} }
    .accordion-item .accordion-content > * {
      @apply my-4 first:mt-0 last:mb-0 {} }
  .accordion-item .accordion-icon {
    @apply text-base text-primary-2 tsn {} }
  .accordion-item.active .accordion-head {
    @apply border-primary-1 {} }
  .accordion-item.active .accordion-title {
    @apply text-primary-1 {} }
  .accordion-item.active .accordion-icon {
    @apply text-primary-1 before:content-['\f068'] {} }

@screen xl {
  .shareholder-4-section .row {
    @apply -mx-10 {} }
    .shareholder-4-section .row > * {
      @apply px-10 {} }
  .shareholder-4-section .col-left {
    @apply w-[calc(560/1480*100%)] {} }
  .shareholder-4-section .col-right {
    @apply w-[calc(920/1480*100%)] {} } }

.shareholder-download-section .table-shareholder-download td:nth-child(1) {
  @apply w-10 text-neutral-500 text-center pr-0 {} }

.shareholder-download-section .table-shareholder-download td:nth-child(2) {
  @apply pl-5 {} }
  .shareholder-download-section .table-shareholder-download td:nth-child(2) a {
    @apply hover-underline {} }
