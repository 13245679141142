header
	@apply fixed top-0 left-0 w-full z-40 bg-white backdrop-blur h-[100px] xl:h-auto xl:bg-white/80 xl:backdrop-blur xl:z-999
	.header-wrap
		@apply flex items-center justify-between gap-4 h-[60px] px-[10px] shadow-drop-shadow-light md:px-4 xl:h-25 xl:px-10
	.header-left
		@apply flex items-center gap-12
	.header-right
		@apply flex items-center gap-[8px] xl:gap-4
	.logo
		@apply w-[116px] h-[50px] xl:rem:w-[172px] xl:h-15
	.navbar-nav
		@apply hidden xl:block
	.main-menu
		@apply flex items-center gap-8
		li
			&:hover
				> a
					@apply text-primary-1 hover-underline-active #{!important}
				> .sub-menu
					@apply opacity-100 pointer-events-auto translate-y-0
			&[class*="current-menu-"]
				> a
					@apply text-primary-1 hover-underline-active #{!important}
			&.menu-item-has-children
				@apply relative
				> a
					@apply relative
					&::after
						@apply font-awesome content-['\f107'] text-base font-black text-primary-1 rem:ml-[10px]
		> li
			@apply h-25 flex-center first:hidden last:hidden
			> a
				@apply flex-center h-full sub-header-20 font-semibold text-black hover-underline
			> .sub-menu
				@apply absolute top-full left-0 rem:w-[320px] bg-neutral-50 border-t-2 border-primary-1 p-10 opacity-0 pointer-events-none tsn translate-y-5 rem:max-h-[calc(100vh-(100/1920*100rem))] overflow-y-auto
	.toggle-submenu
		@apply hidden
	.sub-menu
		@apply space-y-6
		> li
			> a
				@apply body-16 font-medium text-neutral-950
			&.menu-item-has-children
				a
					@apply after:hidden
		.toggle-submenu
			@apply hidden
		.sub-menu
			@apply list-disc pl-5 space-y-3 mt-3
			> li
				> a
					@apply body-14 font-medium text-neutral-950
	.button-language
		@apply order-2 xl:order-1
	.button-search
		@apply order-1 size-[48px] flex-center cursor-pointer text-[24px] text-neutral-400 xl:size-10 xl:text-primary-1 xl:order-2 xl:text-base
	#buttonMenu
		@apply order-3

.mobile-wrap
	@apply fixed top-[60px] w-screen h-[calc(100vh-60px)] bg-primary-3 shadow-drop-shadow-light z-[1001] pt-[14px] pl-[16px] pb-[16px] max-w-[390px] -left-full opacity-0 tsn pointer-events-none xl:hidden
	.navbar-nav-list
		@apply pr-[16px] flex flex-col max-h-[calc(100vh-142px)] overflow-y-auto overflow-x-hidden
	.toggle-submenu
		@apply absolute right-0 z-1 flex justify-end items-center size-[60px] top-0
		&::before
			@apply font-awesome content-['\f054'] text-[16px] font-light text-white tsn
		&.active
			&::before
				@apply rotate-90
	.main-menu
		li
			&[class*="current-menu-"]
				> a
					@apply text-primary-2 font-bold #{!important}
		> li
			@apply border-b border-white/20 relative
			> a
				@apply inline-block py-[16px] text-[18px] font-normal text-white
			> .sub-menu
				@apply pl-5 hidden
	.sub-menu
		@apply space-y-6 pb-5
		> li
			> a
				@apply text-[16px] font-medium text-white
		.toggle-submenu
			@apply hidden
		.sub-menu
			@apply list-disc pl-5 space-y-3 mt-3
			> li
				@apply marker:text-white
				> a
					@apply text-[14px] font-medium text-white

	.mobile-language
		@apply h-[24px] flex items-center gap-[12px] text-[16px] text-white mt-[28px]
		.wpml-ls
			@apply border-l border-neutral-300 pl-3 h-[18px]
			&.wpml-ls-legacy-list-horizontal
				ul
					@apply relative
					li
						@apply flex
						a
							@apply flex h-[18px] w-auto bg-none bg-transparent rounded-none
						&.wpml-ls-current-language
							a
								@apply flex gap-[4px] pointer-events-none
								&::after
									@apply font-awesome content-['\f078'] text-[12px] font-light text-current
						&:not(.wpml-ls-current-language)
							@apply absolute top-full left-0 opacity-0
					&:hover, &:focus
						li
							&:not(.wpml-ls-current-language)
								@apply opacity-100
	&.active
		@apply left-0 opacity-100 pointer-events-auto

.search-wrap
	@apply fixed top-32 left-0 right-0 w-full z-100 hidden px-10 xl:rem:top-[160px]

.wpml-ls
	&.wpml-ls-legacy-dropdown-click
		@apply w-full
		> ul
			> li
				@apply flex-center
				> a
					@apply flex-center text-base font-normal text-neutral-950 bg-none bg-transparent p-0 border-0
					&::after
						@apply font-awesome content-['\f078'] text-sm font-light text-current ml-1 border-none flex static
				>.wpml-ls-sub-menu
					@apply border-none absolute top-full
	&.wpml-ls-legacy-list-horizontal
		ul
			@apply flex items-center gap-2
			li
				a
					@apply size-[48px] rounded-[8px] flex-center tsn bg-secondary-1 xl:bg-none xl:bg-transparent xl:border-b xl:border-transparent xl:rounded-none xl:size-6 xl:opacity-50
				&:hover
					a
						@apply xl:opacity-100
				&.wpml-ls-current-language
					@apply hidden xl:block
					a
						@apply xl:border-primary-1 xl:opacity-100

.site-menu-mobile
	@apply h-[40px] flex items-center gap-6 overflow-hidden max-w-full px-4 py-0 bg-neutral-50 overflow-x-auto xl:hidden
	&::-webkit-scrollbar
		@apply w-0 h-0
	li
		@apply w-max
		a
			@apply whitespace-nowrap text-[16px] font-normal text-black h-[40px] flex-center
		&[class*="current-menu-"]
			a
				@apply text-primary-1

.home-page
	main
		@apply xl:pt-0
