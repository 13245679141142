.foa-dt-1-1-item
	box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.02)
	@apply flex flex-col gap-10 border-2 border-white p-5 rounded-4 bg-white bg-gradient-1 md:flex-row xl:gap-12 xl:p-10 xl:px-12
	.title
		@apply sub-header-24 font-semibold text-primary-2 md:flex-1
	.image
		@apply aspect-[320/240] md:rem:w-[320px]
	&.is-chart-1
		@apply bg-gradient-1 xl:p-10
		.image
			@apply aspect-[600/320] w-full
	&.is-chart-2
		@apply bg-gradient-4 border-none xl:p-10
		.image
			@apply aspect-[600/240] w-full

.foa-dt-1-1-section
	background: linear-gradient(0deg, #C5DEE9 0%, #EDF2F6 100%)
	.block-content
		box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.02)
		@apply p-5 rounded-4 bg-white h-full xl:p-12
	.site-title
		@apply pb-6 border-b border-primary-5 mb-6
	.site-desc
		> *
			@apply mb-5 first:mb-6 last:mb-0
		strong
			@apply sub-header-24 font-semibold text-primary-2
	.foa-1-list
		@apply grid-cols-1 sm:grid-cols-2 gap-10
	.foa-1-item
		@apply bg-white border-white xl:gap-8
		.top
			@apply rem:pb-[22px] border-b border-black/8 flex-row items-center
	.foa-dt-1-1-list
		@apply grid grid-cols-1 gap-10 lg:grid-cols-2

.foa-dt-1-2-section
	background-position: bottom right !important
	@apply rem:min-h-[468px]
	.block-content
		@apply rem:max-w-[1040px]
	.site-title
		@apply pb-6 border-b border-primary-5 mb-6
	.site-desc
		> *
			@apply mb-5 first:mb-6 last:mb-0
		strong
			@apply sub-header-24 font-semibold text-primary-2

.foa-dt-2-1-item
	box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.02)
	.image
		@apply aspect-[680/480]

.foa-dt-2-1-section
	background: linear-gradient(0deg, #C5DEE9 0%, #EDF2F6 100%)
	.block-content
		@apply p-5 rounded-4 bg-white h-full xl:p-10 xl:rem:pb-[50px]
		.site-title
			@apply sub-header-24 font-semibold
	.foa-1-list
		@apply grid-cols-1 sm:grid-cols-2 gap-10 h-full
	.foa-1-item
		@apply bg-white border-white h-full xl:gap-8
		.top
			@apply rem:pb-[22px] border-b border-black/8 flex-row
	.block-image
		@apply p-5 rounded-4 bg-white h-full xl:p-10
		.image
			@apply aspect-[1320/428]
	.slider-top
		@apply flex-center gap-3
		.image
			@apply rem:size-[152px]
		.button-prev, .button-next
			@apply rem:w-[10px] rem:h-[20px]
	.block-slider
		box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.02)
		@apply p-5 rounded-4 bg-gradient-4 overflow-hidden xl:p-12 xl:rem:min-h-[400px]
		.title
			@apply sub-header-24 font-semibold text-white text-center mt-10 mx-auto rem:max-w-[1144px]
	.foa-dt-2-1-list
		@apply grid grid-cols-1 gap-10 lg:grid-cols-2

.foa-dt-2-2-item
	@apply flex flex-col
	.image
		@apply aspect-[680/400] rounded-4
	.button
		@apply absolute left-0 flex-center z-1 top-[20px] w-full pointer-events-none xl:top-10
		a
			@apply pointer-events-auto
	.caption
		@apply py-5 text-center border-b border-neutral-200 xl:rem:py-[25px]
	.title
		@apply sub-header-24 font-semibold text-black text-center hover:text-primary-1
	.desc
		@apply mt-[12px] text-[16px] leading-snug font-normal text-black text-center xl:mt-5 xl:text-xl
	&.is-dealer
		@apply bg-white rounded-4 overflow-hidden md:col-span-2 md:flex-row xl:bg-white
		.image
			@apply aspect-[358/252] md:rem:w-[680px] xl:aspect-[680/400]
		.caption
			@apply border-none flex-center flex-col px-[16px] py-[24px] md:flex-1 md:px-10 xl:px-20
		.title
			@apply xl:text-32 xl:font-bold xl:leading-[calc(40/32)]

.foa-dt-2-2-list
	@apply grid grid-cols-1 gap-10 md:grid-cols-2

.foa-dt-3-1-section
	@apply bg-gradient-4
	.block-content
		box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.02)
		@apply p-5 rounded-4 bg-white h-full xl:p-12
	.site-title
		@apply pb-6 border-b border-primary-5 mb-6
	.site-desc
		> *
			@apply mb-5 first:mb-6 last:mb-0
		strong
			@apply sub-header-24 font-semibold text-primary-2
	.foa-dt-1-1-item
		@apply xl:rem:p-[38px]
		.image
			@apply aspect-[600/280] w-full
	.foa-1-list
		@apply grid-cols-1 sm:grid-cols-2 gap-10
	.foa-1-item
		@apply bg-primary-3 border-primary-3 xl:gap-14
		.top
			@apply rem:pb-[22px] border-b border-white/20 flex-row items-center
		*
			@apply text-white
