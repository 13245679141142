.header-64
	@apply text-[32px] leading-normal xl:text-64

.header-48
	@apply text-[32px] leading-snug xl:text-5xl

.header-40
	@apply text-[28px] leading-snug xl:text-40

.section-header-32
	@apply text-[24px] leading-tight xl:text-32

.sub-header-24
	@apply text-[20px] leading-1.3 xl:text-2xl xl:leading-tight

.sub-header-20
	@apply text-[18px] leading-1.3 xl:text-xl xl:leading-tight

.body-18
	@apply text-[16px] leading-snug xl:text-lg xl:leading-1.33

.body-16
	@apply text-[16px] leading-snug xl:text-base xl:leading-1.33

.body-14
	@apply text-[14px] leading-tight xl:text-sm

.label-12
	@apply text-[12px] leading-snug xl:text-sm

.site-title-large
	@apply header-48 font-bold text-primary-2
	&.is-white
		@apply text-white
	&.is-red
		@apply text-primary-1
	&.is-primary-3
		@apply text-primary-3

.site-title
	@apply header-40 font-bold text-primary-2
	&.is-white
		@apply text-white
	&.is-red
		@apply text-primary-1
	&.is-primary-3
		@apply text-primary-3

.site-sub-title
	@apply section-header-32 font-normal text-primary-2
	&.is-white
		@apply text-white

.site-desc
	@apply body-18 font-normal text-black
	&.is-white
		@apply text-white

.site-full
	@apply body-16 font-normal text-black
	&.is-white
		@apply text-white

.full-content
	@apply prose max-w-none
	@apply body-16 font-normal text-main
	> *
		@apply first:mt-0 last:mb-0
	p
		img
			@apply m-0
	ul
		@apply list-disc pl-4
	ol
		@apply list-decimal pl-4
