// button toggle menu
#buttonMenu
	@apply relative z-50 min-w-[48px] size-[48px] rounded-[8px] bg-primary-1 flex-center
	&::before
		@apply font-awesome content-['\f0c9'] text-[24px] font-normal text-white
	&.active
		&::before
			@apply content-['\f00d']
	@screen xl
		display: none !important

// alert
.alert
	@apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm
	& button
		@apply hidden
	&.alert-danger
		color: #721c24
		background-color: #f8d7da
		border-color: #f5c6cb
	&.alert-success
		color: #155724
		background-color: #d4edda
		border-color: #c3e6cb

.backdrop
	@apply fixed z-50 size-full top-0 left-0 bg-black/75 backdrop-blur hidden
	&.backdrop-mobile
		@apply top-[60px] xl:top-0

.cta-fixed
	@apply fixed z-50 rem:bottom-[102px] right-0 pointer-events-none hidden xl:block
	li
		@apply pt-1
	a
		@apply flex items-center gap-2 bg-primary-3 text-base font-medium text-white shadow-drop-shadow-light pointer-events-auto
		@apply translate-x-[calc(100%-(48/1920*100rem))]
		@apply hover:bg-primary-4 hover:pointer-events-auto hover:translate-x-0
	.icon
		@apply size-12 flex-center text-2xl
	.text
		@apply pr-2

.cta-fixed-2
	@apply fixed z-40 bottom-[50px] right-[20px] flex flex-col gap-[8px] xl:right-25 xl:bottom-8
	.text
		@apply hidden text-sm font-normal text-white whitespace-nowrap xl:block
	.icon
		@apply size-[32px] text-[24px] flex flex-col items-center rounded-full border border-white overflow-hidden xl:size-8 xl:text-2xl
		> *
			@apply size-[32px] min-h-[32px] flex-center tsn xl:size-8 xl:min-h-8
	> *
		@apply size-[40px] rounded-full flex-center text-white text-[24px] bg-primary-3 cursor-pointer tsn xl:h-10 xl:gap-3 xl:w-max xl:p-1 xl:pr-3 xl:text-2xl xl:bg-primary-4
		&:hover
			.icon
				> *
					@apply -translate-y-[32px] xl:-translate-y-8
		&.gas-pump
			@apply bg-primary-1 hover:text-white xl:hidden
	// .back-to-top
	// 	@apply opacity-0 pointer-events-none
	// 	&.active
	// 		@apply opacity-100 pointer-events-auto

.social-list
	ul
		@apply flex flex-wrap gap-2
	a
		@apply w-10 h-10 rounded-full flex-center text-white text-base bg-primary-2 hover:bg-neutral-900

.mobile-menu-fixed
	@apply fixed bottom-0 left-0 w-full bg-primary-1 z-40 md:hidden
	ul
		@apply flex-center
	li
		@apply px-[14px] relative
		& + li
			&::before
				content: ''
				@apply absolute top-1/2 -translate-y-1/2 w-[1px] h-[24px] bg-white/50 left-0
	a
		@apply flex-center gap-[12px] text-[16px] text-center text-white font-normal h-[44px]
		i
			@apply text-[20px]
