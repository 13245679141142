.home-banner
	@apply relative
	.image
		@apply aspect-[390/204] xl:aspect-[1920/960]
		.mb
			@apply md:hidden
			~ img
				@apply hidden md:block
	.quick-links
		@apply hidden xl:block
	.swiper-pagination
		@screen xl
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)
			@apply py-19
	&.banner-full
		.image
			@apply w-screen h-[calc(100vh-100px)] aspect-none md:h-auto md:w-full md:aspect-[1920/960]
			img
				@apply size-full object-cover

.banner-quick-links
	@apply hidden absolute z-10 bottom-0 left-1/2 -translate-x-1/2 w-full rounded-t-4 overflow-hidden px-0 xl:flex-center
	a
		@apply flex-center sub-header-20 font-semibold text-white px-5 py-4 bg-primary-3 backdrop-blur opacity-70 tsn flex-grow h-16
		@apply hover:opacity-100
		&:nth-child(2)
			@apply bg-primary-4
		&:nth-child(3)
			@apply bg-primary-2
