.contact-iframe
	.iframe-scale
		@apply pt-[calc(720/1920*100%)]

.box-contact-form
	@apply spacing-mobile bg-neutral-50 xl:p-12
	@apply rounded-none
	.row
		@apply rem:-mx-[6px]
		> *
			@apply rem:px-[6px]
	.form-group
		&.form-submit
			@apply flex-center
		button
			@apply text-primary-2 border-current

.box-contact
	@apply xl:rem:max-w-[660px] xl:py-10
	.site-desc
		@apply body-16 pt-5 mt-5 border-t border-[#ccc] xl:mt-8 xl:pt-8
		> *
			@apply my-5 first:mt-0 last:mb-0
		a
			@apply hover:text-primary-1
		ul
			@apply list-disc pl-5 space-y-1
