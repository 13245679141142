@keyframes ctaPhoneRing
	0%
		transform: rotate(0) scale(1) skew(1deg)
	10%
		transform: rotate(-15deg) scale(1) skew(1deg)
	20%
		transform: rotate(15deg) scale(1) skew(1deg)
	30%
		transform: rotate(-15deg) scale(1) skew(1deg)
	40%
		transform: rotate(15deg) scale(1) skew(1deg)
	50%
		transform: rotate(0) scale(1) skew(1deg)
	100%
		transform: rotate(0) scale(1) skew(1deg)
