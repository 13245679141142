.podcast-group-left
	@apply flex items-center flex-1
.podcast-group-right
	@apply flex items-center justify-end flex-1 gap-2
// .podcast-group-mid
// 	@apply flex-grow
.podcast-timeline
	@apply h-3 relative rem:my-[10px]
.podcast-empty
	@apply h-1 absolute top-1/2 -translate-y-1/2 left-0 w-full z-1 bg-secondary-2 cursor-pointer
.podcast-current
	@apply absolute top-1/2 -translate-y-1/2 left-0 h-1 z-2 bg-primary-2 pointer-events-none
	.podcast-node
		@apply absolute top-1/2 -translate-y-1/2 rem:-right-[6px] size-3 z-2 bg-primary-2 rounded-full
.podcast-time-count
	@apply flex items-center gap-2 justify-between body-16 font-normal text-neutral-500 xl:mb-1
.podcast-control-bar
	@apply h-18 flex items-center gap-2 justify-between mt-5 xl:rem:mt-[30px]
.podcast-button-flex
	@apply flex-center gap-2
.podcast-button
	@apply flex-center gap-1
	&::before
		content: ''
		@apply font-awesome-sharp text-base text-primary-2 font-normal leading-none
	&.podcast-sound-muted-btn
		@apply hidden
		@apply before:content-['\f6a9']
	&.podcast-sound-min-btn
		@apply before:content-['\f026']
		&.muted
			@apply before:content-['\f6a9']
	&.podcast-sound-max-btn
		@apply before:content-['\f028']
		&.muted
			@apply before:content-['\f6a9']
	&.podcast-play-btn
		@apply before:content-['\f04b'] before:text-white before:font-thin
	&.podcast-pause-btn
		@apply hidden
		@apply before:content-['\f04c'] before:text-white before:font-bold
	&.podcast-prev-btn
		@apply before:content-['\f0e2'] after:content-['10']
	&.podcast-next-btn
		@apply flex-row-reverse before:content-['\f01e'] after:content-['10']
.podcast-play
	@apply size-16 flex-center rounded-full overflow-hidden
	> *
		@apply size-full bg-primary-2 rounded-full
	.podcast-button
		@apply before:text-2xl before:size-full before:flex-center
.podcast-button-white
	@apply flex-center h-9 bg-white rounded-full px-3 text-primary-2 body-16 font-normal whitespace-nowrap select-none
.podcast-playback-rate
	@apply cursor-pointer
	.podcast-playback-rate-title
		@apply hidden md:block
	.podcast-playback-rate
		@apply font-bold
.podcast-author
	@media only screen and (max-width: 1023.98px)
		@apply hidden
.podcast-volume
	@apply rem:w-[220px] hidden justify-between gap-1 md:flex
.podcast-volume-bar
	@apply h-2 flex-1 relative ml-2
	&::before
		content: ''
		@apply absolute top-1/2 -translate-y-1/2 -left-2 w-2 rem:h-[2px] bg-primary-2 pointer-events-none z-1
.podcast-volume-empty
	@apply absolute top-1/2 -translate-y-1/2 left-0 w-full h-full bg-transparent cursor-pointer
	&::before
		content: ''
		@apply absolute top-1/2 -translate-y-1/2 -left-2 w-full rem:h-[2px] bg-secondary-2 pointer-events-none -z-1
.podcast-volume-current
	@apply absolute top-1/2 -translate-y-1/2 left-0 w-full rem:h-[2px] bg-primary-2 pointer-events-none
.podcast-volume-node
	@apply absolute top-1/2 -translate-y-1/2 right-0 size-2 z-2 bg-primary-2 rounded-full
.podcast-volume-sound
	@apply md:hidden
.podcast-full-content
	@apply p-4 bg-white rounded-2 xl:p-6 xl:rounded-4

.podcast-timeline-item
	@apply flex items-center body-16 font-medium text-neutral-950 cursor-pointer py-2 border-t border-secondary-2 first:border-t-0 xl:py-4
	.time
		@apply relative w-14 min-w-14 text-sm text-primary-4
		&::before
			content: ''
			@apply absolute top-1/2 -translate-y-1/2 right-2 w-px rem:h-[10px] bg-neutral-200 pointer-events-none
		~ span
			@apply hover:text-primary-2 hover-underline

.box-podcast
	@apply spacing-mobile bg-neutral-50 rem:max-w-[1200px] mx-auto xl:rounded-4 xl:p-10

.podcast-player-wrap
	@apply md:mb-10
	.media-item
		@apply rounded-none
		.image
			@apply aspect-[240/160] rounded-2
		.title
			@apply flex items-center gap-3 hover:text-primary-2
		.caption
			@apply bg-none bg-transparent px-0 relative
		&:hover
			.title
				@apply text-primary-2
		@screen md
			@apply flex-row relative
			.image
				@apply rem:w-[240px] rem:h-[160px]
			.caption
				@apply py-3 pl-5 rem:max-w-[500px] static
		@screen xl

	.social-list
		@apply absolute top-5 right-0 z-1 md:top-0
		ul
			@apply justify-end
		a
			@apply bg-primary-3 xl:size-10 hover:bg-primary-2

.podcast-other-item
	@apply hidden
	.podcast-other-flex
		@apply flex items-center justify-between gap-2 py-3 border-b border-secondary-2
	.title
		@apply body-16 font-bold text-primary-2
		a
			@apply flex items-center gap-2 hover-underline
	.right
		@apply flex items-center gap-2
	.icon
		@apply size-8 flex-center rounded-full bg-primary-2 text-sm text-white
