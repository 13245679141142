.button
	@apply flex flex-wrap gap-5

.link
	@apply flex-center gap-2 body-16 font-medium text-primary-2
	@apply hover:text-primary-2
	.icon
		@apply text-lg rem:h-[30px] rem:w-[18px] flex items-center overflow-hidden
		> *
			@apply rem:h-[30px] rem:w-[18px] rem:min-w-[18px] flex-center tsn -translate-x-full
	&:hover
		.icon
			> *
				@apply translate-x-0
	&.is-white
		@apply text-white
		@apply hover:text-white
	&.is-light-blue
		@apply text-primary-3
		@apply hover:text-primary-3
	&.is-download
		@apply gap-3
		.icon
			@apply size-10 border-2 border-current rounded-full flex flex-col items-start justify-start
			> *
				@apply size-full min-h-full translate-x-0 -translate-y-full
		&:hover
			.icon
				> *
					@apply translate-y-0

.btn-solid
	@apply flex-center gap-2 py-2 px-4 min-h-12 bg-primary-1 sub-header-20 font-semibold text-white rounded-full tsn xl:px-6
	@apply hover:text-white
	.icon
		@apply text-lg rem:h-[30px] rem:w-[18px] flex items-center overflow-hidden
		> *
			@apply rem:h-[30px] rem:w-[18px] rem:min-w-[18px] flex-center tsn -translate-x-full
	&:hover
		box-shadow: 0px 0px 8px 0px theme('colors.primary.1')
		.icon
			> *
				@apply translate-x-0
	&.is-white
		@apply bg-white text-primary-1
		@apply hover:text-primary-1

.btn-lined
	@apply flex-center gap-2 py-2 px-4 min-h-12 border border-primary-1 sub-header-20 font-semibold text-primary-1 rounded-full xl:px-6
	@apply hover:text-primary-1
	.icon
		@apply text-lg rem:h-[30px] rem:w-[18px] flex items-center overflow-hidden
		> *
			@apply rem:h-[30px] rem:w-[18px] rem:min-w-[18px] flex-center tsn -translate-x-full
	&:hover
		.icon
			> *
				@apply translate-x-0
	&.is-white
		@apply border-white text-white
		@apply hover:text-white
	&.is-blue
		@apply border-primary-2 text-primary-2
		@apply hover:text-primary-2
	&.is-icon
		@apply min-h-0 p-0 size-[40px] min-w-[40px] overflow-hidden xl:min-w-10 xl:size-10
		.icon
			@apply h-full
			> *
				@apply h-full
		&.is-large-icon
			@apply xl:size-16
			.icon
				@apply xl:text-xl
