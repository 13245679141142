.swiper-pagination
	.swiper-pagination-bullet
		@apply opacity-100 size-[8px] rounded-full bg-primary-2/50 mx-[6px] xl:size-2 xl:rem:mx-[6px]
		&.swiper-pagination-bullet-active
			@apply bg-primary-2 w-[24px] xl:w-6
	&.is-white
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)
		@apply bottom-0 left-0 py-[16px]
		.swiper-pagination-bullet
			@apply bg-white/50
			&.swiper-pagination-bullet-active
				@apply bg-white

.swiper-button
	@apply flex-center gap-3
	> *
		@apply size-12 rounded-full flex-center border border-primary-2 text-primary-2 tsn bg-white
		@apply hover:bg-primary-2 hover:text-white
		&.swiper-button-disabled
			@apply opacity-40
		&.swiper-button-lock
			@apply opacity-0 pointer-events-none
	&.is-lined
		> *
			@apply size-10 bg-none bg-transparent border border-primary-2 text-primary-2 rounded-full xl:size-12 xl:text-lg
	&.is-white
		> *
			@apply text-white bg-white/20 border-white hover:text-neutral-200
	&.is-abs
		> *
			@apply absolute top-1/2 -translate-y-1/2 z-1
		.button-prev
			@apply -left-5 xl:-left-22
		.button-next
			@apply -right-5 xl:-right-22
		&.is-top-20
			> *
				@apply top-[20%]
		&.is-top-30
			> *
				@apply top-[30%]
		&.is-top-40
			> *
				@apply top-[40%]

.swiper-relative
	@apply xl:px-0
	&.is-page
		@apply pb-10 lg:pb-0
	.swiper-scrollbar
		@apply h-[4px] bg-neutral-200 rounded-none left-0 w-full
		.swiper-scrollbar-drag
			@apply bg-primary-1 hover:bg-primary-1 rounded-none
