.shareholder-1-item
	@apply flex flex-col rounded-[8px] overflow-hidden lg:flex-row xl:rounded-4
	.caption
		@apply spacing-mobile xl:p-16 bg-gradient-4 flex-1
		@apply rounded-none
	.image
		@apply aspect-[880/480] lg:w-2/3 xl:rem:w-[880px]
	.news-date
		@apply text-base text-white
	.title
		@apply sub-header-24 font-bold text-white leading-tight
	.desc
		@apply body-16 font-normal text-white
	.line
		@apply border-t border-white my-5 xl:my-10

.shareholder-1-section
	@apply xl:rem:pt-[92px]
	.block-content
		@apply rem:max-w-[880px]
	.home-4-list
		@apply grid grid-cols-2 gap-5 md:grid-cols-4 xl:gap-10

.table-shareholder-download
	table
		@apply w-full
	th
		@apply py-[8px] border-b border-primary-3 xl:py-5
		&:first-child
			@apply text-left sub-header-24 font-bold text-primary-2 pr-5
		&:last-child
			@apply text-right
			label
				@apply body-16 font-normal text-primary-2
	.form-group
		@apply flex justify-end items-center gap-3
	td
		@apply py-[8px] border-b border-neutral-200 xl:py-3
		a
			@apply body-16 font-normal text-primary-2
		&:first-child
			@apply pr-5
	.news-date
		@apply body-16 whitespace-nowrap
	.shareholder-flex
		@apply flex items-center justify-end gap-3 xl:gap-5
	.button
		a
			@apply whitespace-nowrap font-medium
	@media only screen and (max-width: 767.98px)
		@apply overflow-x-auto overflow-y-hidden
		table
			@apply min-w-[600px]

.box-shareholder-2
	@apply spacing-mobile bg-white xl:p-10
	.pagination
		@apply xl:justify-end

.shareholder-3-item
	@apply flex flex-col gap-2
	.image
		@apply aspect-[440/280] rounded-4
	.caption
		@apply py-3 flex flex-col gap-3 flex-1
	.title
		@apply sub-header-24 font-normal text-black hover:text-primary-1

.shareholder-3-list
	@apply grid grid-cols-3 gap-10

.box-shareholder-4-form
	@apply spacing-mobile bg-gradient-4 xl:p-12
	.form-group
		input, textarea
			@apply border-0 border-b border-white/40 px-0 rem:h-[38px] body-16 font-normal text-white bg-transparent rounded-none
			@apply placeholder:text-white
			@apply focus:ring-0 focus:border-white
		textarea
			@apply h-20 py-2
	.form-submit
		button
			@apply ml-auto mr-0

.accordion-item
	@apply border-b border-neutral-200
	.accordion-head
		@apply flex gap-4 justify-between py-4 border-b border-transparent tsn cursor-pointer
	.accordion-title
		@apply body-18 font-bold text-primary-2 tsn
	.accordion-content
		@apply py-5 body-16 font-normal text-black hidden
		> *
			@apply my-4 first:mt-0 last:mb-0
	.accordion-icon
		@apply text-base text-primary-2 tsn
	&.active
		.accordion-head
			@apply border-primary-1
		.accordion-title
			@apply text-primary-1
		.accordion-icon
			@apply text-primary-1 before:content-['\f068']

.shareholder-4-section
	@screen xl
		.row
			@apply -mx-10
			> *
				@apply px-10
		.col-left
			@apply w-[calc(560/1480*100%)]
		.col-right
			@apply w-[calc(920/1480*100%)]

.shareholder-download-section
	.table-shareholder-download
		td
			&:nth-child(1)
				@apply w-10 text-neutral-500 text-center pr-0
			&:nth-child(2)
				@apply pl-5
				a
					@apply hover-underline
