.pagination
	@apply flex-center flex-wrap gap-3 mt-10 xl:mt-12 xl:gap-5
	span, a
		@apply body-16 font-medium text-neutral-500 tsn flex items-center min-w-3 border-b border-transparent
		@apply hover:text-primary-2
	li
		&.active
			span, a
				@apply text-primary-2 border-primary-3
	.prev, .next
		a
			@apply size-10 flex-center border border-primary-2 rounded-full text-lg text-primary-2 tsn hover:bg-primary-2 hover:text-white
		&.disabled
			a
				@apply opacity-20 pointer-events-none
